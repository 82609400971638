import React, { useEffect, useState, Component } from "react";
import MyNavBar from "./myNavBar.js";
import { Map, CircleMarker, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import MyScreenFooter from "./myScreenFooter.js";
import moment from "moment";
import ReactLoading from "react-loading";
import { Chart } from "react-google-charts";
import { Card, CardBody, CardTitle, Container, Row, Col, CardHeader, CardText } from "reactstrap";

let basurl = window.location.hostname;
let userName = localStorage.getItem("userName");
let companyId = localStorage.getItem("Company");

function MyDashBoardScreen(params) {
  const [results, setResults] = useState();
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(false);
  const [grouppedOrders, setGrouppedOrders] = useState();
  const [stockTotalQuantity, setStockTotalQuantity] = useState(0);
  const [stockTotalList, setStockTotalList] = useState([]);
  const [totalGiro, setTotalGiro] = useState(0);
  const [salesTotalQuantity, setSalesTotalQuantity] = useState(0);
  const [salesTotalList, setSalesTotalList] = useState([]);
  const [returnTotalQuantity, setReturnTotalQuantity] = useState(0);
  const [exitedTotalQuantity, setExitedTotalQuantity] = useState(0);
  const [supplierPurchasedQuantity, setSupplierPurchasedQuantity] = useState(0);
  // const [screenWidthRate, setScreenWidthRate] = useState("15%");
  // setScreenWidthRate(window.innerWidth > 410 ? "15%" : "100%");
  const [customerReportData, setCustomerReportData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [logisticsSitesResult, setLogisticsSitesResult] = useState([]);
  const [requestTypeBasedResult, setRequestTypeBasedResult] = useState([]);

  const [bubleChartData, setBubleChartData] = useState([]);
  const [trips, setTrips] = useState([]);
  const [selectedTripId, setSelectedTripId] = useState(0);
  const [selectedAddressId, setSelectedAddressId] = useState(0);
  const [showTripSelectionScreen, setShowTripSelectionScreen] = useState(false);
  const [sankeyExportData, setSankeyExportData] = useState([]);
  const [sankeyImportData, setSankeyImportData] = useState([]);

  const [beginDate, setBeginDate] = useState(moment().subtract(1, "months").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));

  const position = [40.4651676, 37.7945229];
  const zoom = 5;
  const refMap = React.createRef();

  var colors = ["#a6cee3", "#b2df8a", "#fb9a99", "#fdbf6f", "#cab2d6", "#ffff99", "#1f78b4", "#33a02c"];

  const handleEnterKeyPress = () => {
    setLoading(true);
    const url = `http://${basurl}:9091/api/myDashboardAPI/getDashboardReports`;
    axios
      .get(url, {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
        params: {
          beginDate: beginDate,
          endDate: endDate,
        },
      })
      .then((res) => {
        if (res.data) {
          setResults(res.data);
          const countedOrders = {};
          setBarChartData(res.data.uniqueOrders.barChartData);
          setLogisticsSitesResult(res.data.uniqueOrders.logisticsSitesResult);
          setRequestTypeBasedResult(res.data.uniqueOrders.requestTypeBasedResult);
          setSankeyExportData(res.data.uniqueOrders.sankeyExportData);
          setSankeyImportData(res.data.uniqueOrders.sankeyImportData);
          console.log("res.data.uniqueOrders.sankeyData-->", res.data.uniqueOrders.sankeyData);
          const uniqueOrders = res.data.uniqueOrders.uniqueOrders;
          const logisticsSites = ["GAZİANTEP", "LONDON", "İSTANBUL"];
          uniqueOrders &&
            uniqueOrders.length &&
            uniqueOrders.map((row) => {
              const typeKey = row.RequestType;
              const siteKey = logisticsSites.includes(row.LogisticsSite) ? row.LogisticsSite : "LONDON";
              if (!countedOrders[typeKey]) {
                countedOrders[typeKey] = 1;
              } else {
                countedOrders[typeKey] += 1;
              }

              if (!countedOrders[siteKey]) {
                countedOrders[siteKey] = 1;
              } else {
                countedOrders[siteKey] += 1;
              }
            });
          setGrouppedOrders(countedOrders);
          setOrders(uniqueOrders);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error-->", error));
  };

  return (
    <div className="App">
      <div className="Form2">
        <MyNavBar userName={userName} conpanyID={companyId} style={{ zIndex: 10 }}></MyNavBar>
      </div>
      <div className="Form1">
        {loading ? (
          <div className="loading-spinner">
            <ReactLoading className="spinner" type="spinningBubbles" color="#7E4B26" height="40%" width="10%" />
          </div>
        ) : (
          <div>
            <Container fluid className="border border-info">
              <Row style={{ marginBottom: "10px" }}>
                <Col lg="4">
                  <br></br>
                  Başlangıç Tarihi :
                  <input
                    type="date"
                    required
                    name="beginDate"
                    value={beginDate}
                    onChange={(e) => setBeginDate(e.target.value)}
                    className=""
                  />
                  &nbsp;&nbsp; Bitiş Tarihi :
                  <input
                    type="date"
                    required
                    name="endDate"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className=""
                  />
                  <br></br>
                </Col>
                <Col lg="3">
                  <button
                    style={{ minWidth: "150px", marginTop: "16px", color: "#473122" }}
                    className="btn btn-warning"
                    onClick={handleEnterKeyPress}
                  >
                    Sorgula
                  </button>
                </Col>
                <Col lg="5"></Col>
              </Row>
            </Container>
            <Container fluid className="border border-info">
              <Container fluid className="border border-info">
                <Row>
                  <Col lg="6">
                    <h2>Export-Yuk Akis Raporu</h2>
                    {sankeyExportData && sankeyExportData.length ? (
                      <Chart
                        chartType="Sankey"
                        height="800px"
                        data={sankeyExportData}
                        options={{
                          title: "my awesome chart",
                          sankey: {
                            link: { color: { fill: colors }, colorMode: "gradient" },
                            interactivity: true, // Allows you to select nodes.
                            node: {
                              colors: "#f0ad4e",
                              label: { color: "#062263", fontSize: 15, bold: true },
                            },
                          },
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col lg="6">
                    <h2>Import-Yuk Akis Raporu</h2>
                    {sankeyImportData && sankeyImportData.length ? (
                      <Chart
                        chartType="Sankey"
                        height="800px"
                        data={sankeyImportData}
                        options={{
                          title: "my awesome chart",
                          sankey: {
                            link: { color: { fill: colors }, colorMode: "gradient" },
                            interactivity: true, // Allows you to select nodes.
                            node: {
                              colors: ["#630b06"],
                              label: { color: "#062263", fontSize: 15, bold: true },
                            },
                          },
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col md={12}>
                    {barChartData && barChartData.length ? (
                      <div className="Form1">
                        <Container fluid className="border border-info">
                          <Row>
                            <Col lg="12">
                              <Chart
                                height={400}
                                chartType="BarChart"
                                backgroundColor="blue"
                                loader={<div>Loading Chart</div>}
                                data={barChartData}
                                options={{
                                  // Material design options
                                  title: `Tasima yaptigimiz top 10 Ulke Raporu->(from:${beginDate} to:${endDate})`,
                                  hAxis: {
                                    title: "Toplam Siparis Sayisi",
                                    minValue: 0,
                                  },
                                  colors: ["#7E4B26"],
                                  backgroundColor: "#d4e2d4",
                                  chartArea: {
                                    backgroundColor: {
                                      fill: "#F4F4F4",
                                      opacity: 100,
                                      width: "60%",
                                      fontSize: "30px",
                                    },
                                  },
                                  vAxis: {
                                    title: "Ulkeler",
                                    textStyle: { fontSize: 15, fonthWeight: "600" },
                                  },
                                  legend: { position: "top", maxLines: 3 },
                                }}
                                // For tests
                                rootProps={{ "data-testid": "2" }}
                              />
                            </Col>
                            <Col lg="0"></Col>
                          </Row>
                        </Container>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    {logisticsSitesResult && logisticsSitesResult.length ? (
                      <div className="Form1">
                        <Container fluid className="border border-info">
                          <Row>
                            <Col lg="12">
                              <Chart
                                height={400}
                                chartType="BarChart"
                                backgroundColor="blue"
                                loader={<div>Loading Chart</div>}
                                data={logisticsSitesResult}
                                options={{
                                  // Material design options
                                  title: `Operasyon Merkezi bazli siparis raporu->(from:${beginDate} to:${endDate})`,
                                  hAxis: {
                                    title: "Siparis Sayisi",
                                    minValue: 0,
                                  },
                                  colors: ["#7E4B26"],
                                  backgroundColor: "#d4e2d4",
                                  chartArea: {
                                    backgroundColor: {
                                      fill: "#F4F4F4",
                                      opacity: 100,
                                      width: "60%",
                                      fontSize: "30px",
                                    },
                                  },
                                  vAxis: {
                                    title: "Operasyon Merkezleri",
                                    textStyle: { fontSize: 15, fonthWeight: "600" },
                                  },
                                  legend: { position: "top", maxLines: 3 },
                                }}
                                // For tests
                                rootProps={{ "data-testid": "2" }}
                              />
                            </Col>
                            <Col lg="0"></Col>
                          </Row>
                        </Container>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col lg="6">
                    {requestTypeBasedResult && requestTypeBasedResult.length ? (
                      <div className="Form1">
                        <Container fluid className="border border-info">
                          <Row>
                            <Col lg="12">
                              <Chart
                                height={400}
                                chartType="BarChart"
                                backgroundColor="blue"
                                loader={<div>Loading Chart</div>}
                                data={requestTypeBasedResult}
                                options={{
                                  // Material design options
                                  title: `Siparis Tipi bazli siparis raporu->(from:${beginDate} to:${endDate})`,
                                  hAxis: {
                                    title: "Siparis Sayisi",
                                    minValue: 0,
                                  },
                                  colors: ["#7E4B26"],
                                  backgroundColor: "#d4e2d4",
                                  chartArea: {
                                    backgroundColor: {
                                      fill: "#F4F4F4",
                                      opacity: 100,
                                      width: "60%",
                                      fontSize: "30px",
                                    },
                                  },
                                  vAxis: {
                                    title: "Siparis Tipleri",
                                    textStyle: { fontSize: 15, fonthWeight: "600" },
                                  },
                                  legend: { position: "top", maxLines: 3 },
                                }}
                                // For tests
                                rootProps={{ "data-testid": "2" }}
                              />
                            </Col>
                            <Col lg="0"></Col>
                          </Row>
                        </Container>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <br></br>
              </Container>

              <Row>
                <Col>
                  <h2>Website Talep Tipi Bazli Istatistikler</h2>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col lg="3">
                  <Row>
                    <Col lg="12">
                      <button style={{ height: "60px", marginBottom: "3px" }} className="btn btn-success">
                        Toplam Kargo Takip:{" "}
                        {results &&
                          results.WebsiteAPIgetParcelTrackingHistoryTrackingHistory &&
                          results.WebsiteAPIgetParcelTrackingHistoryTrackingHistory.quantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </button>
                    </Col>
                  </Row>
                </Col>

                <Col lg="3">
                  <Row>
                    <Col lg="12">
                      <button style={{ height: "30px", marginBottom: "3px" }} className="btn btn-danger">
                        Toplam YM:{" "}
                        {results &&
                          results.WebsiteAPIgetPriceForRequestYM &&
                          results.WebsiteAPIgetPriceForRequestYM.quantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <button style={{ height: "30px" }} className="btn btn-warning">
                        Toplam KT:{" "}
                        {results &&
                          results.WebsiteAPIgetPriceForRequestKT &&
                          results.WebsiteAPIgetPriceForRequestKT.quantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </button>
                    </Col>
                  </Row>
                </Col>

                <Col lg="3">
                  <Row>
                    <Col lg="12">
                      <button style={{ height: "30px", marginBottom: "3px" }} className="btn btn-info">
                        Toplam AT:{" "}
                        {results &&
                          results.WebsiteAPIgetPriceForRequestAT &&
                          results.WebsiteAPIgetPriceForRequestAT.quantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <button style={{ height: "30px", marginBottom: "3px" }} className="btn btn-primary">
                        Toplam TE:{" "}
                        {results &&
                          results.WebsiteAPIgetPriceForRequestTE &&
                          results.WebsiteAPIgetPriceForRequestTE.quantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </button>
                    </Col>
                  </Row>
                </Col>

                <Col lg="3">
                  <Row>
                    <Col lg="12">
                      <button style={{ height: "30px", marginBottom: "3px" }} className="btn btn-danger">
                        Toplam ZE:{" "}
                        {results &&
                          results.WebsiteAPIgetPriceForRequestZE &&
                          results.WebsiteAPIgetPriceForRequestZE.quantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <button style={{ height: "30px", marginBottom: "3px" }} className="btn btn-success">
                        Toplam ZK:{" "}
                        {results &&
                          results.WebsiteAPIgetPriceForRequestZK &&
                          results.WebsiteAPIgetPriceForRequestZK.quantity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12"></Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 style={{ fontSize: "20px" }}>Domain Bazli Istatistikler</h3>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col lg="4">
                  <button style={{ height: "30px", marginBottom: "3px" }} className="btn btn-primary">
                    abstransport.com.tr:{" "}
                    {results &&
                      results.WebsiteAPIgetPriceForRequestabstransportcomtr &&
                      results.WebsiteAPIgetPriceForRequestabstransportcomtr.quantity
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </button>
                </Col>
                <Col lg="4">
                  <button style={{ height: "30px", marginBottom: "3px" }} className="btn btn-success">
                    absremovals.com:{" "}
                    {results &&
                      results.WebsiteAPIgetPriceForRequestabsremovalscom &&
                      results.WebsiteAPIgetPriceForRequestabsremovalscom.quantity
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </button>
                </Col>
                <Col lg="4">
                  <button style={{ height: "30px", marginBottom: "3px" }} className="btn btn-info">
                    abstransport.co.uk:{" "}
                    {results &&
                      results.WebsiteAPIgetPriceForRequestabstransportcouk &&
                      results.WebsiteAPIgetPriceForRequestabstransportcouk.quantity
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>

      <div className="Form1">
        {/* <Map ref={refMap} center={position} zoom={zoom} style={{ height: "400px", width: "100%", zIndex: 0 }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
          {stocks
            ? stocks.map((stock) => (
                <CircleMarker
                  key={stock.MainCustomerID.toString() + stock.OpId.toString()}
                  // onClick={() => alert(stocks.find((e) => e.OpId === Id)["OpCenter"])}
                  center={[stocks.find((e) => e.OpId === stock.OpId).Latitude, stocks.find((e) => e.OpId === stock.OpId).Longitude]}
                  color={
                    stocks.find((e) => e.OpId === stock.OpId).Quantity > 400
                      ? "#007965"
                      : stocks.find((e) => e.OpId === stock.OpId).MainCustomerID == 1
                      ? "#fa1e0e"
                      : stocks.find((e) => e.OpId === stock.OpId).MainCustomerID == 2
                      ? "#ffc75f"
                      : stocks.find((e) => e.OpId === stock.OpId).MainCustomerID == 3
                      ? "#8ac4d0"
                      : "#413c69"
                  }
                  radius="20"
                  fillOpacity={stocks.find((e) => e.OpId === stock.OpId).Quantity > 400 ? 0.8 : 0.4}
                  stroke={false}
                >
                  <Popup>
                    <Container fluid className="border border-info">
                      <Row>
                        <Col lg={12}>
                          <span style={{ fontSize: "11px" }}>
                            <br />
                            {stocks.find((e) => e.OpId === stock.OpId)["MainCustomer"]} &nbsp;||&nbsp;{stocks.find((e) => e.OpId === stock.OpId)["DepotName"]}
                            &nbsp;|| Toplam Miktar:{" "}
                            {stocks
                              .filter((e) => e.OpId === stock.OpId)
                              .reduce((currentTotal, item) => {
                                return item.Quantity + currentTotal;
                              }, 0)}
                          </span>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col lg="6">
                          <button class="btn btn-success" onClick={() => handleAddOrdersInPopup(stock.OpId)}>
                            {" "}
                            Siparis Bazli At
                          </button>
                        </Col>
                        <Col lg="6">
                          <button class="btn btn-warning" onClick={() => handleAddOrdersInPopup(stock.OpId)}>
                            {" "}
                            Sefere At
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Popup>
                </CircleMarker>
              ))
            : "Loading..."}
        </Map> */}
      </div>
      <div className="Form1">
        <MyScreenFooter
          screenName="Ana Rapor Ekranı"
          userNameFooter={userName}
          conpanyIDFooter={companyId}
        ></MyScreenFooter>
      </div>

      {/* <Modal
        isOpen={showTripSelectionScreen}
        onRequestClose={() => setShowTripSelectionScreen(false)}
        animationType="fade"
        transparent={true}
        style={{
          transform: "translate(-50%, -50%)",
          content: { color: "orange", height: "33%", width: "%20" },
        }}
      >
        <p>Sefer Seçiniz!</p>
        <Select
          name="selectedTripId"
          value={trips.find((e) => e.value === parseInt(selectedTripId))}
          placeholder="Sefer Seçiniz"
          styles={customStyles}
          onChange={(e) => setSelectedTripId(e.value)}
          options={trips}
        />
        &nbsp;&nbsp;
        <button value="setShowTripSelectionScreen" className="btn btn-success" onClick={handleAddOrdersToSelectedTrip}>
          Seçilen Sefere Siparişleri At
        </button>
      </Modal> */}
    </div>
  );
}

export default MyDashBoardScreen; //exporting a component make it reusable and this is the beauty of react
