import React, { useState, useEffect } from 'react'
import axios from 'axios';
import MyNavBar from '../myNavBar.js'
import MyScreenFooter from '../myScreenFooter.js'
import Modal from 'react-modal'
import ConvertToBase64 from '../../tools/convertToBase64.js'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Container, Row, Col } from 'react-bootstrap';  
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MyProductManagementScreen(params) {
  const [productList, setProductList] = useState([])
  const [code, setCode] = useState(null)
  const [description, setDescription] = useState(null)
  const [group, setGroup] = useState(null)
  const [volume, setVolume] = useState(null)
  const [maxVolume, setMaxVolume] = useState(null)
  const [weight, setWeight] = useState(null)
  const [maxWeight, setMaxWeight] = useState(null)
  const [price, setPrice] = useState(null);
  const [image, setImage] = useState(null);
  const [active, setActive] = useState(true);
  const [isShowOnWebSite, setIsShowOnWebSite] = useState(false);
  const [isMultiPart, setIsMultiPart] = useState(false);
  const [selected, setSelected] = useState(0)
  const [warningMessage, setWarningMessage] = useState("")
  const [edit, setEdit] = useState(false)
  const [selectProductId, setSelectProductId] = useState(0)

  const [type, setType] = useState("")
  const [size, setSize] = useState(0)
  const [openTypeWarning, setOpenTypeWarning] = useState(0)
  const [openSizeWarning, setOpenSizeWarning] = useState(0)
  const [openSelectionWarning, setOpenSelectionWarning] = useState(0)

  const [uploadedFile, setUploadedFile] = useState([])

  const closeTypeWarningModal = () => setOpenTypeWarning(0);
  const closeSizeWarningModal = () => setOpenSizeWarning(0);
  const closeSelectionWarningModal = () => setOpenSelectionWarning(0);

  const formData = new FormData();
  let basurl = window.location.hostname
  let userName = localStorage.getItem("UserName");
  let companyId = localStorage.getItem("CompanyID");

  function getFiles(files) {
    setType(files[0]["type"])
    setSize(files[0]["size"])
    if ((files[0]["type"] != String("image/jpeg"))) {
      setOpenTypeWarning(1);
      setUploadedFile([]);
    }
    if (files[0]["size"] > 3000000) {
      setOpenSizeWarning(1);
      setUploadedFile([]);
    }
    //  console.log(files[0]);
    // Convert file to base64 string
    setImage(files[0]["base64"]);
  };

  function handleSubmit(event) {
    // console.log(image);
    event.preventDefault();
    if(!code || !description || !group){
      toast.error("Kod,Description ve Group Bilgisi alanlari dolu olmalidir. Lutfen kontrol edip tekrar deneyin!")
    }else{
      if(edit){
        fetch(`http://${basurl}:9091/api/ManagementAPI/updateItem/${selectProductId}`, {
          method: 'POST',
          redirect: 'follow',
          headers: {
            'Content-Type': 'application/json',
            "auth-token": localStorage.getItem("token") 
          },
          body: JSON.stringify({
            Code: code,
            Description: description,
            Group: group,
            Volume: volume,
            MaxVolume: maxVolume,
            Weight:weight,
            MaxWeight:maxWeight,
            Price: price,
            Image: image,
            Operation: edit,
            IsMultiPart: isMultiPart ? 1 : 0,
            IsActive: active ? 1 : 0,
            IsShowOnWebSite: isShowOnWebSite ? 1 : 0
          })
        })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            if (data.ok === 1) {
              window.location.href = `/myProductManagementScreen/`;
            }
          })
          .catch(err => console.log(err));
        }if(!edit){
          fetch(`http://${basurl}:9091/api/ManagementAPI/insertItem`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
              'Content-Type': 'application/json',
              "auth-token": localStorage.getItem("token") 
            },
            body: JSON.stringify({
              Code: code,
              Description: description,
              Group: group,
              Volume: volume,
              MaxVolume: maxVolume,
              Weight:weight,
              MaxWeight:maxWeight,
              Price: price,
              Image: image,
              IsMultiPart: isMultiPart ? 1 : 0,
              IsActive: active ? 1 : 0,
              IsShowOnWebSite: isShowOnWebSite ? 1 : 0
            })
          })
            .then(res => res.json())
            .then(data => {
              console.log(data)
              if (data.status === 1) {
                window.location.href = `/myProductManagementScreen/`;
              }else{
                toast(data.message)
              }
            })
            .catch(err => console.log(err));
        }
    }
  }

  function compare(a, b) {
    const pkA = a.Id;
    const pkB = b.Id;

    let comparison = 0;

    if (pkA > pkB) {
      comparison = -1;
    } else if (pkA < pkB) {
      comparison = 1;
    }
    return comparison;
  }

  function getAllProductList(event) {
    setEdit(false)
    event.preventDefault();
    const url = `http://${basurl}:9091/api/ManagementAPI/getItems`
    axios.get(url, 
      { headers: { 
        "auth-token": localStorage.getItem("token")
    },  
    params: {
      Code:code?code:'',
      Description:description?description:'',
      Group:group?group:''
    }
})
      .then(res => {
        console.log(res.data);
        const productList = res.data.sort(res.data.Group);
        setProductList(productList.sort(compare));
        productList.forEach(product => product["selected"] = 0)
      });
    renderTableData();
  }

  function handleEditClick() {
    setEdit(true)
    //console.log(productList.filter(product=>product["selected"]===1));
    if (productList.filter(product => product["selected"] === 1).length === 0) {
      setWarningMessage("Herhangi bir malzeme seçilmemiş. Lütfen seçip tekrar deneyin.")
      setOpenSelectionWarning(1)
    }
    if (productList.filter(product => product["selected"] === 1).length > 1) {
      setWarningMessage("Birden fazla malzeme seçilmiş. Lütfen kontrol edip tekrar deneyin.")
      setOpenSelectionWarning(1)
    }
    if (productList.filter(product => product["selected"] === 1).length === 1) {
      //console.log(productList.filter(product=>product["selected"]===1));
      setSelectProductId((productList.filter(product => product["selected"] === 1))[0]["_id"])
      setCode((productList.filter(product => product["selected"] === 1))[0]["Code"]);
      setDescription((productList.filter(product => product["selected"] === 1))[0]["Description"]);
      setGroup((productList.filter(product => product["selected"] === 1))[0]["Group"]);
      setVolume((productList.filter(product => product["selected"] === 1))[0]["Volume"]);
      setMaxVolume((productList.filter(product => product["selected"] === 1))[0]["MaxVolume"]);
      setWeight((productList.filter(product => product["selected"] === 1))[0]["Weight"]);
      setMaxWeight((productList.filter(product => product["selected"] === 1))[0]["MaxWeight"]);
      setPrice((productList.filter(product => product["selected"] === 1))[0]["Price"]);
      setImage((productList.filter(product => product["selected"] === 1))[0]["Image"]);;
      setIsMultiPart((productList.filter(product => product["selected"] === 1))[0]["IsMultiPart"]);
      setActive((productList.filter(product => product["selected"] === 1))[0]["IsActive"]);
      setIsShowOnWebSite((productList.filter(product => product["selected"] === 1))[0]["IsShowOnWebSite"])
    }
  };

  function handleSelection(e) {
    let selectedID = e.target.value
    productList.map((product) => {
      if (product["_id"] === selectedID & product["selected"] === 0) {
        product["selected"] = 1
        console.log(product);
      } else if (product["_id"] === selectedID & product["selected"] === 1) {
        product["selected"] = 0
      }
    });
  }

  function renderTableData() {
    return productList.map((product, index) => {
      const { _id, Code, Description, Group, Volume,MaxVolume,Weight,MaxWeight, Price, Image,IsMultiPart, IsActive,IsShowOnWebSite } = product //destructuring
      return (
        <tr key={_id}>
          <td><input type="checkbox" selected={selected} onChange={handleSelection} value={_id} name="_id" />&nbsp;</td>
          <td>{Code}</td>
          <td style={{width:"30%"}}>{Description}</td>
          <td>{Group}</td>
          <td>{Volume}</td>
          <td>{MaxVolume}</td>
          <td>{Weight}</td>
          <td>{MaxWeight}</td>
          <td>{Price}</td>
          <td style={{textAlign:"center"}}>{Image ? <img style={{ height: "70px" }} src={Image} />
                : null}</td>
          <td><input type="checkbox" checked={IsMultiPart ? 1 : 0} />&nbsp;</td>
          <td><input type="checkbox" checked={IsActive ? 1 : 0} />&nbsp;</td>
          <td><input type="checkbox" checked={IsShowOnWebSite ? 1 : 0} />&nbsp;</td>
        </tr>
      )
    })
  }

  //<input  type="file" name="productImage" onChange={(e)=>fileHandling(e)} className="form-control"/><br></br>
  return (
    <div>
      <ToastContainer position="top-center" autoClose={5000} closeOnClick/>
      <div className="Form1">
        <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
      </div>
      <form className="Form2">
        <Container fluid className="border border-info">
          <Row>
            <Col sm={4}>
              <input type="text" placeholder="Kod" required name="code" value={code} onChange={e => setCode(e.target.value)} className="form-control" />
            </Col>
            <Col sm={8}>
              <input type="text" placeholder="Tanım" required name="description" value={description} onChange={e => setDescription(e.target.value)} className="form-control" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
             <input type="text" placeholder="Group Bilgisi" required name="group" value={group} onChange={e => setGroup(e.target.value)} className="form-control" />
            </Col>
            <Col sm={6}>
              <input type="number" placeholder="Fiyat" value={price} name="price" onChange={e => setPrice(e.target.value)} className="form-control" />
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
             <input type="number" placeholder="Min Hacim" name="volume" value={volume} onChange={e => setVolume(e.target.value)} className="form-control" />
            </Col>
            <Col sm={3}>
             <input type="number" placeholder="Max Hacim"  name="maxVolume" value={maxVolume} onChange={e => setMaxVolume(e.target.value)} className="form-control" />
            </Col>
            <Col sm={3}>
             <input type="number" placeholder="Min Agirlik"  name="weight" value={weight} onChange={e => setWeight(e.target.value)} className="form-control" />
            </Col>
            <Col sm={3}>
             <input type="number" placeholder="Max Agirlik"  name="maxWeight" value={maxWeight} onChange={e => setMaxWeight(e.target.value)} className="form-control" />
            </Col>
          </Row><br></br>
          <Row>
            <Col sm={4}>
              <b>Coklu Parcali mi?</b>&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="checkbox" checked={isMultiPart} required name="isMultiPart" onChange={e => setIsMultiPart(e.target.checked)} />
            </Col>
            <Col sm={4}>
              <b>Aktif?</b>&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="checkbox" checked={active} required name="active" onChange={e => setActive(e.target.checked)} />
            </Col>
            <Col sm={4}>
              <b>Web Sitesinde Goster?</b>&nbsp;&nbsp;&nbsp;&nbsp;
              <input type="checkbox" checked={isShowOnWebSite} required name="isShowOnWebSite" onChange={e => setIsShowOnWebSite(e.target.checked)} />
            </Col>
          </Row>&nbsp;
        </Container>
        <Container fluid className="border border-info">
          <Row>
            <Col sm={12}>
              <label className="grey-text"> Ürün Fotosu:</label>
              {image?
                <img style={{ height: "150px" }} src={image} />
                : null}
              <ConvertToBase64 multiple={true} onDone={getFiles} />
            </Col>
          </Row>
        </Container>
        <Container fluid className="border border-info">
          &nbsp;
          <Row>
            <Col sm={4}>
              <button type="button" onClick={handleSubmit} className="btn btn-success">Kaydet</button>
            </Col>
            <Col sm={4}>
              <button type="button" onClick={getAllProductList} className="btn btn-warning">Listeyi Getir</button>
            </Col>
            <Col sm={4}>
              <button type="button" onClick={handleEditClick} className="btn btn-Info">Düzenle</button>
            </Col>
          </Row>&nbsp;
        </Container>
      </form>

      <div className="Form1">
        <table id="table-to-xls" className="Form1">
          <tr>
            <th>Seç</th>
            <th>Kod</th>
            <th>Tanım</th>
            <th>Grup</th>
            <th>Volume</th>
            <th>MaxVolume</th>
            <th>Weight</th>
            <th>MaxWeight</th>
            <th>Price</th>
            <th>Image</th>
            <th>Coklu Parcali mi?</th>
            <th>Aktif?</th>
            <th>Web Sitesinde Goster?</th>
          </tr>
          <tbody>
            {renderTableData()}
          </tbody>
        </table>
      </div>
      <div className="Form1">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Download as XLS" />
      </div>

      <div className="Form1">
        <MyScreenFooter screenName="Malzeme Tanımlama Ekranı" userNameFooter={userName} conpanyIDFooter={companyId}></MyScreenFooter>
      </div>
      <Modal
        isOpen={openTypeWarning}
        onRequestClose={() => setOpenTypeWarning(0)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "30%" }
        }}
      >
        <h2>Uyarı!!!!</h2>
        <p>Yüklenen dosya formatı hatalı. Ancak Fotoğraf yükleyebilirsiniz. Lütfen kontrol edip tekrar deneyiniz!</p>
        <br></br>
        <div>
          <button value="closeSelectionModal" className="btn btn-success" onClick={closeTypeWarningModal}>Kapat</button>
        </div>
      </Modal>
      <Modal
        isOpen={openSizeWarning}
        onRequestClose={() => setOpenSizeWarning(0)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "30%" }
        }}
      >
        <h2>Uyarı!!!!</h2>
        <p>Yüklenen dosya boyutu hatalı. Maksimum 1MB boyutunda dosya yükleyebilirsiniz. Lütfen kontrol edip tekrar deneyiniz!</p>
        <br></br>
        <div>
          <button value="closeSelectionModal" className="btn btn-success" onClick={closeSizeWarningModal}>Kapat</button>
        </div>
      </Modal>

      <Modal
        isOpen={openSelectionWarning}
        onRequestClose={() => setOpenSelectionWarning(0)}
        style={{
          overlay: { backgroundColor: "grey" },
          content: { color: "orange", height: "30%" }
        }}
      >
        <h2>Uyarı!!!!</h2>
        <p>{warningMessage}</p>
        <br></br>
        <div>
          <button value="closeSelectionModal" className="btn btn-success" onClick={closeSelectionWarningModal}>Kapat</button>
        </div>
      </Modal>

    </div>
  )
}

export default MyProductManagementScreen;
