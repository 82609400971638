import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import '../css/crm.css'

function CommentPageModal(params) {
  const { comment, setComment, handleAddCommentButton } = params;

  return (
    <div className="row" style={{ paddingRight: "40px" }}>
      <Container fluid>
        <Row>
          <Col>
            <textarea
              type="text"
              placeholder="Yorum"
              required
              rows="5"
              name="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="boxsizingBorder"
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <button 
            className="btn btn-success getAddress"
            onClick={handleAddCommentButton}
            >
              Yorum Ekle
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CommentPageModal;
