import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import backGroundImage from "../imgs/website-background.svg";
import logo from "../imgs/abs.nareppo.logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

import MyNavBar from "./myNavBar.js";
import MyScreenFooter from "./myScreenFooter.js";
import LandingPageModal from "./components/LandingPageModal";

import Modal from "react-modal";
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
//https://colorhunt.co/palette/116678   ---color palette
let basurl = window.location.hostname;
let userName = localStorage.getItem("userName");
let companyId = localStorage.getItem("CompanyID");
// console.log(window.innerWidth);

function MyCustomerLandingPage(params) {
  let { PhoneNumber, UserCode } = useParams();

  const [orders, setOrders] = useState([]);
  const [showWeKnowPhoneNumber, setWeKnowPhoneNumber] = useState(false);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (PhoneNumber) {
      console.log('PhoneNumber--->',PhoneNumber);
      fetch(`http://${basurl}:9091/api/ManagementAPI/crm`, {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          phoneNumber: PhoneNumber,
          userCode: UserCode,
        }),
      })
        .then((res) => {
          if (res.status === 498) {
            window.location.href = `/MyLoginScreen/`;
            localStorage.setItem("token", "");
            localStorage.setItem("userName", "");
          }
          return res.json();
        })
        .then((data) => {
          if (!data.success) {
            toast.warning(`${PhoneNumber} ${data.message}`);
          } else {
            localStorage.setItem("token", data.user.token);
            localStorage.setItem("userName", data.user.email);
            localStorage.setItem("type", data.user.type);
            setCustomers(data.customers);
            setOrders(data.orders);
            setWeKnowPhoneNumber(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [PhoneNumber]);

  const getMeToOrderListScreen = () => {
    window.location.href = `/MyCustomerLandingPage/`;
  };

  const goToMyCustomerManagementScreen = () => {
    window.location.href = `/MyCustomerManagementScreen/`;
  };

  const goMyCurrentCustomerManagementScreen = () => {
    if (PhoneNumber) {
      window.location.href = `/MyCurrentCustomerManagementScreen/${PhoneNumber}/${UserCode}`;
    } else {
      window.location.href = `/MyCurrentCustomerManagementScreen`;
    }
  };

  return (
    <div className="master">
      <div className="MasterTitle"></div>
      <div className="Form1">
        <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
      </div>
      <ToastContainer draggable pauseOnHover newestOnTop={true} position="top-center" />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img src={logo} alt="abs.nareppo.com logo" width="15%" height="auto"></img>
          </Col>
        </Row>
        {showWeKnowPhoneNumber ? (
          ""
        ) : (
          <div>
            <Container fluid className="background-container">
              <Row>
                <Col sm={12}>
                  <img src={backGroundImage} alt="Website Background"></img>
                  <button className="btn" onClick={goToMyCustomerManagementScreen}>
                    Yeni Kayit
                  </button>
                  <br></br>
                  <button className="btn2" onClick={goMyCurrentCustomerManagementScreen}>
                    Kayitli Musteri
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Container>

      <div className="Form1">
        <Container fluid>
          <Row>
            <Col sm={6}></Col>
            <Col sm={6}></Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col></Col>
          </Row>
        </Container>
      </div>
      {showWeKnowPhoneNumber ? (
        <Modal
          isOpen={showWeKnowPhoneNumber}
          onRequestClose={() => setWeKnowPhoneNumber(false)}
          style={{
            overlay: { backgroundColor: "#8ea6b4" },
            content: { color: "#984a59", backgroundColor: "#e7eff3", height: "85%" },
          }}
        >
          <LandingPageModal
            PhoneNumber={PhoneNumber}
            UserCode={userName}
            getMeToOrderListScreen={getMeToOrderListScreen}
            customers={customers}
            orders={orders}
          ></LandingPageModal>
        </Modal>
      ) : (
        ""
      )}

      <div className="Form1" style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <MyScreenFooter
          screenName="Customer Landing Page"
          userNameFooter={userName}
          conpanyIDFooter={companyId}
        ></MyScreenFooter>
      </div>
    </div>
  );
}

export default MyCustomerLandingPage;
