import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";

function LandingPageModal(params) {
  let basurl = window.location.hostname;
  const { PhoneNumber, UserCode, getMeToOrderListScreen, customers, orders } = params;
  const [comment, setComment] = useState("");
  function handleAddCommentButton() {
    fetch(`http://${basurl}:9091/api/ManagementAPI/crm/add-comment-customer`, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        customerId: customers[0]._id,
        comment: comment,
      }),
    })
      .then((res) => {
        if (res.status === 498) {
          window.location.href = `/MyLoginScreen/`;
          localStorage.setItem("token", "");
          localStorage.setItem("userName", "");
        }
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    fetch(`http://${basurl}:9091/api/ManagementAPI/crm/log-call-for-customer`, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        customerId: customers[0]._id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((err) => console.log(err));
  }, []);
  return !customers[0] ? (
    ""
  ) : (
    <div className="row" style={{ paddingRight: "40px" }}>
      <Container fluid>
        <Row>
          <Col lg="12">
            <h3 style={{ textAlign: "center", fontSize: "20px" }}>{customers[0].CustomerNumber.length&&customers[0].CustomerNumber.join(' ; ')} Sistemde Kayıtlı!</h3>
            <h3 style={{ textAlign: "center", fontSize: "30px" }}>
              {customers[0].CustomerName} Sinifi---{customers[0].Type}{" "}
            </h3>
            <Container fluid>
              <Row>
                <Col sm={12}>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="Button" eventKey="0">
                          Müşteri Detay Bilgileri
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          {" "}
                          <React.Fragment>
                            Musteri Adi: {customers[0].CustomerName}
                            <br></br>
                            Musteri Sinifi: {customers[0].Type}
                            <br></br>
                            Musteri Email: {customers[0].CustomerEmail}
                            <br></br>
                            Muhasebe Musteri Kodu: {customers[0].ReferenceNumber}
                            <br></br>
                            Adresler:
                            <br></br>
                            {customers[0].Addresses.map((e, i) => {
                              const AddressText = e.AddressText || "";
                              const Country = e.Country || "";
                              const PostCode = e.PostCode || "";
                              const City = e.City || "";
                              return (
                                <React.Fragment key={i + 1}>
                                  {i + 1 + "-" + AddressText + " " + PostCode + " " + City + " " + Country}
                                  <br />
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="Button" eventKey="1">
                          Müşteri Açıkta Olan Siparişleri ve Durumları
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <React.Fragment>
                            <table>
                              {orders.map((e, i) => {
                                const TrackingNumber = e.TrackingNumber || "";
                                const RequestType = e.RequestType || "";
                                const Date = moment(e.Date).format("DD.MM.YYYY HH:mm") || "";
                                return (
                                  <tbody>
                                    <tr>
                                      <td style={{ maxWidth: "100px" }} key={i}>
                                        <React.Fragment>
                                          {i + 1 + "-" + RequestType + " " + TrackingNumber + " =>" + Date}
                                          <br />
                                        </React.Fragment>
                                      </td>
                                      <td key={i}>
                                        {e.history.map((h, j) => (
                                          <React.Fragment key={j}>
                                            {j +
                                              1 +
                                              " > " +
                                              moment(h.TransactionDate).format("DD.MM.YYYY HH:mm") +
                                              " " +
                                              h.TransactionDetail +
                                              " " +
                                              (h.OperationCenter ? h.OperationCenter : "")}
                                            <br />
                                          </React.Fragment>
                                        ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </React.Fragment>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="Button" eventKey="2">
                          Müşteri Yorumları
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <React.Fragment>
                            <table>
                              {customers[0].Comments.map((e, i) => {
                                const Comment = e.Comment || "";
                                const User = e.EnteredBy || "";
                                const Date = moment(e.Date).format("DD.MM.YYYY HH:mm") || "";
                                return (
                                  <tbody>
                                    <tr key={i}>
                                      <td style={{ maxWidth: "300px" }} key={i + 1000}>
                                        <React.Fragment>
                                          {i + 1 + "-" + Comment}
                                          <br />
                                        </React.Fragment>
                                      </td>
                                      <td key={i + 1001}>
                                        <React.Fragment>
                                          {User}
                                          <br />
                                        </React.Fragment>
                                      </td>
                                      <td key={i + 1002}>
                                        <React.Fragment>
                                          {Date}
                                          <br />
                                        </React.Fragment>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </React.Fragment>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="Button" eventKey="3">
                          Yorum Ekle
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <React.Fragment>
                            {" "}
                            <div className="row" style={{ paddingRight: "40px" }}>
                              <Container fluid>
                                <Row>
                                  <Col>
                                    <textarea
                                      type="text"
                                      placeholder="Yorum"
                                      required
                                      rows="5"
                                      name="comment"
                                      value={comment}
                                      onChange={(e) => setComment(e.target.value)}
                                      className="boxsizingBorder"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <button className="btn btn-primary getAddress" onClick={handleAddCommentButton}>
                                      Yorum Ekle
                                    </button>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          </React.Fragment>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="Button" eventKey="4">
                          Arama Gecmisi
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <React.Fragment>
                            <table>
                              {customers[0].CallInfo.sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                .slice(0, 10)
                                .map((e, i) => {
                                  const User = e.User || "";
                                  const Date = moment(e.Date).format("DD.MM.YYYY HH:mm") || "";
                                  return (
                                    <tbody>
                                      <tr key={i}>
                                        <td key={i+1000}>
                                          <React.Fragment>
                                            {i + 1 + "-" + User}
                                            <br />
                                          </React.Fragment>
                                        </td>
                                        <td key={i + 1001}>
                                          <React.Fragment>
                                            {Date}
                                            <br />
                                          </React.Fragment>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })}
                            </table>
                          </React.Fragment>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <br></br>
                  <button value="submitDeliveryForm" className="btn btn-warning" onClick={getMeToOrderListScreen}>
                    Sipariş Listeleme Ekranına Geç
                  </button>
                  <br></br>
                  <br></br>
                  {/* <button value="submitDeliveryForm" className="btn btn-info" onClick={getAuthenticationFromMyPayPod}>
                MyPaypod Data Gonder
              </button> */}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LandingPageModal;
