const countries = [
  {
    "label": "Turkey",
    "value": "Turkey",
    "code": "TR",
    "dial": 90
  },
  {
    "label": "United Kingdom",
    "value": "United Kingdom",
    "code": "UK",
    "dial": 44
  },
  {
    "label": "Wales",
    "value": "Wales",
    "code": "Wales",
    "dial": 44
  },
  {
    "label": "Scotland",
    "value": "Scotland",
    "code": "Scotland",
    "dial": 44
  },
  {
    "label": "Germany",
    "value": "Germany",
    "code": "DE",
    "dial": 49
  },
  {
    "label": "France",
    "value": "France",
    "code": "FR",
    "dial": 33
  },
  {
    "label": "Italy",
    "value": "Italy",
    "code": "IT",
    "dial": 39
  },
  {
    "label": "Spain",
    "value": "Spain",
    "code": "ES",
    "dial": 34
  },
  {
    "label": "Ukraine",
    "value": "Ukraine",
    "code": "UA",
    "dial": 380
  },
  {
    "label": "Poland",
    "value": "Poland",
    "code": "PL",
    "dial": 48
  },
  {
    "label": "Romania",
    "value": "Romania",
    "code": "RO",
    "dial": 40
  },
  {
    "label": "Netherlands",
    "value": "Netherlands",
    "code": "NP",
    "dial": 977
  },
  {
    "label": "Belgium",
    "value": "Belgium",
    "code": "BE",
    "dial": 32
  },
  {
    "label": "Czechia",
    "value": "Czechia",
    "code": "CI",
    "dial": 225
  },
  {
    "label": "Greece",
    "value": "Greece",
    "code": "GR",
    "dial": 30
  },
  {
    "label": "Portugal",
    "value": "Portugal",
    "code": "PT",
    "dial": 351
  },
  {
    "label": "Sweden",
    "value": "Sweden",
    "code": "SE",
    "dial": 46
  },
  {
    "label": "Hungary",
    "value": "Hungary",
    "code": "HU",
    "dial": 36
  },
  {
    "label": "Belarus",
    "value": "Belarus",
    "code": "BY",
    "dial": 375
  },
  {
    "label": "Austria",
    "value": "Austria",
    "code": "AT",
    "dial": 43
  },
  {
    "label": "Serbia",
    "value": "Serbia",
    "code": "RS",
    "dial": 381
  },
  {
    "label": "Switzerland",
    "value": "Switzerland",
    "code": "CH",
    "dial": 41
  },
  {
    "label": "Bulgaria",
    "value": "Bulgaria",
    "code": "BG",
    "dial": 359
  },
  {
    "label": "Denmark",
    "value": "Denmark",
    "code": "DK",
    "dial": 45
  },
  {
    "label": "Finland",
    "value": "Finland",
    "code": "FI",
    "dial": 358
  },
  {
    "label": "Slovakia",
    "value": "Slovakia",
    "code": "SK",
    "dial": 421
  },
  {
    "label": "Norway",
    "value": "Norway",
    "code": "NO",
    "dial": 47
  },
  {
    "label": "Ireland",
    "value": "Ireland",
    "code": "IE",
    "dial": 353
  },
  {
    "label": "Croatia",
    "value": "Croatia",
    "code": "HR",
    "dial": 385
  },
  {
    "label": "Moldova",
    "value": "Moldova",
    "code": "FM",
    "dial": 691
  },
  {
    "label": "Bosnia and Herzegovina",
    "value": "Bosnia and Herzegovina",
    "code": "BA",
    "dial": 387
  },
  {
    "label": "Albania",
    "value": "Albania",
    "code": "AL",
    "dial": 355
  },
  {
    "label": "Lithuania",
    "value": "Lithuania",
    "code": "LT",
    "dial": 370
  },
  {
    "label": "North Macedonia",
    "value": "North Macedonia",
    "code": "NF",
    "dial": 672
  },
  {
    "label": "Slovenia",
    "value": "Slovenia",
    "code": "SI",
    "dial": 386
  },
  {
    "label": "Latvia",
    "value": "Latvia",
    "code": "LV",
    "dial": 371
  },
  {
    "label": "Estonia",
    "value": "Estonia",
    "code": "EE",
    "dial": 372
  },
  {
    "label": "Montenegro",
    "value": "Montenegro",
    "code": "ME",
    "dial": 382
  },
  {
    "label": "Luxembourg",
    "value": "Luxembourg",
    "code": "LU",
    "dial": 352
  },
  {
    "label": "Malta",
    "value": "Malta",
    "code": "MT",
    "dial": 356
  },
  {
    "label": "Iceland",
    "value": "Iceland",
    "code": "IS",
    "dial": 354
  },
  {
    "label": "Andorra",
    "value": "Andorra",
    "code": "AD",
    "dial": 376
  },
  {
    "label": "Monaco",
    "value": "Monaco",
    "code": "MC",
    "dial": 377
  },
  {
    "label": "Liechtenstein",
    "value": "Liechtenstein",
    "code": "LI",
    "dial": 423
  },
  {
    "label": "San Marino",
    "value": "San Marino",
    "code": "SM",
    "dial": 378
  },
  {
    "label": "Holy See",
    "value": "Holy See",
    "code": "HM",
    "dial": 672
  }
 ];
 
  export default countries;