import React, { useState, useEffect, useMemo } from "react";
import Style from './App.css'
import UserAvatar from '../imgs/user.jpg'
import { usePosition } from 'use-position';
import axios from 'axios';

// <img src={UserAvatar} alt={params.match.params.userName} height="40"></img>
// <p>{params.userName1}||{params.companyId1}</p>

function MyScreenFooter(params) {
  const { latitude, longitude, error } = usePosition();
  const [address, setAddress] = useState('')

  useEffect(() => {
    if (latitude) {
      const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
      axios.get(url)
        .then(res => {
          setAddress(res.data.display_name)
          //console.log(res.data.display_name);
        })
    }
  }, [latitude, longitude])

  return (
    <div className="row">
      <div className="column">
        <img src={UserAvatar} alt={params["userNameFooter"]} height="30px"></img>
        <h3>{params["userNameFooter"]}||{params["conpanyIDFooter"]}</h3>
      </div>
      <div className="column" fontSize="10px">
        <h3>{address}</h3>
      </div>
      <div className="column">
        <h3>{params["screenName"]}</h3>
        <h3>support:orcasinformatica@gmail.com</h3>
      </div>
    </div>
  )
}

export default MyScreenFooter //exporting a component make it reusable and this is the beauty of react
