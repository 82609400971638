import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../css/crm.css";
import Select from "react-select";
import customStyles from "../css/customerStyle";
import countries from "../const/countries";
import axios, { post } from "axios";

function CustomerAddressPanelModal(params) {
  const { customerId, setShowAddAddress, setWarningMessage, setShowWarning, handleEnterKeyPress } = params;
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [city, setCity] = useState("");
  const [town, setTown] = useState("");
  const [postCode, setPostCode] = useState("");
  const [street, setStreet] = useState("");
  const [gateNumber, setGateNumber] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({});
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addressText, setAddressText] = useState("");
  const [cities, setCities] = useState([]);
  const [towns, setTowns] = useState([]);
  const [cityCode, setCityCode] = useState("");
  let basurl = window.location.hostname;

  useEffect(() => {
    const url1 = `http://${basurl}:9091/api/masterDataAPI/getCitiesAPI`;
    axios
      .get(url1, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res1) => {
        const cities = res1.data;
        setCities(cities);
      });
  }, []);

  useEffect(() => {
    const url2 = `http://${basurl}:9091/api/masterDataAPI/getTownsAPI`;
    axios
      .get(url2, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        params: {
          CityCode: cityCode,
        },
      })
      .then((res2) => {
        const towns = res2.data;
        setTowns(towns);
      });
  }, [cityCode]);

  const handleGetAddressOptions = () => {
    setAddresses([]);
    const url2 = `http://${basurl}:9091/api/masterDataAPI/getAddressesFromPostCodeAPI`;
    axios
      .get(url2, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        params: {
          PostCode: postCode,
        },
      })
      .then((res2) => {
        if (res2.data.success) {
          const addresses = res2.data?.addresses;
          setAddresses(addresses);
        } else {
          setAddresses([]);
          setAddress({});
          setCity("");
          setTown("");
          setPostCode("");
          setStreet("");
          setGateNumber("");
          setWarningMessage(
            "Secilen arama kriterlerine bagli olarak herhangi bir adress detayi bulunamadi. Lutfen kontrol edip tekrar deneyin!"
          );
          setShowWarning(true);
        }
      });
  };

  const getAddressDetailsFromPostCode = async (public_id) => {
    const apiResult = await axios.get(`http://${basurl}:9091/api/masterDataAPI/getAddressDetailsFromPostCodeAPI`, {
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      params: {
        SelectedAddress: public_id,
      },
    });
    const addressResponse = apiResult?.data?.address?.result?.address_components;
    setAddress(addressResponse);
    const country = addressResponse.find((add) => add.types.includes("country"));
    const city = addressResponse.find((add) => add.types.includes("administrative_area_level_1"));
    const town = addressResponse.find((add) => add.types.includes("locality"));
    const postCode = addressResponse.find((add) => add.types.includes("postal_codes"));
    const street = addressResponse.find((add) => add.types.includes("route"));
    const gateNumber = addressResponse.find((add) => add.types.includes("street_number"));
    setCity(city?.short_name);
    setTown(town?.short_name);
    setPostCode(postCode?.short_name);
    setStreet(street?.short_name);
    setGateNumber(gateNumber?.short_name);
  };

  const handleAddAddress = () => {
    fetch(`http://${basurl}:9091/api/ManagementAPI/crm/add-address-customer`, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        customerId: customerId,
        address: {
          Country: country,
          City: city,
          Town: town,
          PostCode: postCode,
          Street: street,
          GateNumber: gateNumber,
          AddressText: addressText,
        },
      }),
    })
      .then((res) => {
        if (res.status === 498) {
          window.location.href = `/MyLoginScreen/`;
          localStorage.setItem("token", "");
          localStorage.setItem("userName", "");
        }
        return res.json();
      })
      .then((data) => {
        console.log("data-->", data);
        if (data?.success) {
          setShowAddAddress(false);
          handleEnterKeyPress({ e: { key: "Enter" } });
        } else {
          setShowWarning(true);
          setWarningMessage(data?.message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="row" style={{ paddingRight: "40px" }}>
      <Container fluid>
        {!country ? (
          <Row>
            <Col>
              {
                <Select
                  name="country"
                  required
                  onChange={(e) => {
                    setCountry(e.value);
                    setCountryCode(e.code);
                  }}
                  options={countries}
                  styles={customStyles}
                  value={countries.find((c) => c.value == country)}
                  defaultValue={{ label: "Select Country", value: 0 }}
                />
              }
            </Col>
          </Row>
        ) : countryCode == "UK" ? (
          <Row>
            <Col lg={10}>
              <input
                type="text"
                placeholder="Posta Kodu bilgisini giriniz!!!"
                required
                name="postCode"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
                className="square border border-warning postCode"
              />
            </Col>
            <Col lg={2}>
              <button className="btn btn-danger getAddress" onClick={handleGetAddressOptions}>
                Adresleri Getir
              </button>
            </Col>
          </Row>
        ) : countryCode == "TR" ? (
          <Row>
            <Col lg={6}>
              <Row>
                <Col>
                  {
                    <Select
                      name="city"
                      required
                      onChange={(e) => {
                        setCity(e.value);
                        setCityCode(e.code);
                      }}
                      options={cities}
                      styles={customStyles}
                      value={cities.find((c) => c.value == city)}
                      defaultValue={{ label: "Select City", value: 0 }}
                    />
                  }
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col>
                  {
                    <Select
                      name="town"
                      required
                      onChange={(e) => {
                        setTown(e.value);
                      }}
                      options={towns}
                      styles={customStyles}
                      value={towns.find((c) => c.value == town)}
                      defaultValue={{ label: "Select Town", value: 0 }}
                    />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          ""
        )}

        {addresses && addresses.length ? (
          <Row>
            <Col lg={8}>
              <Select
                name="address"
                required
                onChange={(e) => {
                  setSelectedAddress(e.value);
                  setAddressText(e.label);
                  getAddressDetailsFromPostCode(e.value);
                }}
                options={addresses}
                styles={customStyles}
                value={addresses.find((a) => a.value == selectedAddress)}
                defaultValue={{ label: "Select Address", value: 0 }}
              />
            </Col>
          </Row>
        ) : (
          ""
        )}
        {(address && address.length && postCode) || (city && town) || !["", "UK", "TR"].includes(countryCode) ? (
          <Row>
            <Col lg={4}>
              <input
                type="text"
                placeholder="Sehir bilgisini giriniz!!!"
                required
                readOnly={countryCode == "TR" ? true : false}
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="square border border-warning postCode"
              />
            </Col>
            <Col lg={4}>
              <input
                type="text"
                placeholder="Ilce/Town bilgisini giriniz!!!"
                required
                readOnly={countryCode == "TR" ? true : false}
                name="town"
                value={town}
                onChange={(e) => setTown(e.target.value)}
                className="square border border-warning postCode"
              />
            </Col>

            {countryCode == "TR" ? (
              ""
            ) : (
              <>
                <Col lg={4}>
                  <input
                    type="text"
                    placeholder="Cadde/Mahalle bilgisini giriniz!!!"
                    required
                    name="street"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    className="square border border-warning postCode"
                  />
                </Col>
                <Col lg={4}>
                  <input
                    type="text"
                    placeholder="Kapi Numarasi bilgisini giriniz!!!"
                    required
                    name="gateNumber"
                    value={gateNumber}
                    onChange={(e) => setGateNumber(e.target.value)}
                    className="square border border-warning postCode"
                  />
                </Col>
              </>
            )}
            <Col lg={12}>
              <input
                type="text"
                placeholder="Address bilgisini giriniz!!!"
                required
                name="addressText"
                value={addressText}
                onChange={(e) => setAddressText(e.target.value)}
                className="square border border-warning postCode"
              />
            </Col>
          </Row>
        ) : (
          ""
        )}
        {(address && address.length) || (city && town) || !["", "UK", "TR"].includes(countryCode) ? (
          <Row>
            <Col lg={3}>
              <button className="btn btn-success getAddress" onClick={handleAddAddress}>
                Adres Ekle
              </button>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Container>
    </div>
  );
}

export default CustomerAddressPanelModal;
