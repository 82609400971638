import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../css/crm.css";
let basurl = window.location.hostname;

function CustomerEmailPanelModal(params) {
  const { customerId, setShowEmailModal, handleEnterKeyPress } = params;
  const [email, setEmail] = useState("");

  function handleAddEmailButton() {
    setShowEmailModal(false);
    fetch(`http://${basurl}:9091/api/ManagementAPI/crm/add-email-customer`, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        customerId: customerId,
        email: email,
      }),
    })
      .then((res) => {
        if (res.status === 498) {
          window.location.href = `/MyLoginScreen/`;
          localStorage.setItem("token", "");
          localStorage.setItem("userName", "");
        }
        return res.json();
      })
      .then((data) => {
        handleEnterKeyPress({ e: { key: "Enter" } });
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="row" style={{ paddingRight: "40px" }}>
      <Container fluid>
        <Row>
          <Col lg="10" className="button-column">
            <Row>
              <h4>Email Bilgisini Giriniz</h4>
            </Row>
            <Row>
              <input
                type="text"
                placeholder="Email bilgisini giriniz!"
                required
                rows="5"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="postCode"
              />
            </Row>
          </Col>
          <Col lg="2" className="button-column">
            <Row className="topElement"></Row>
            <Row>
              <button className="btn btn-warning submitButton" onClick={handleAddEmailButton}>
                Email Ekle
              </button>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CustomerEmailPanelModal;
