import React from "react";
import "bootstrap/js/src/collapse.js";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/js/src/collapse.js";
import "./css/crm.css";

function MyNavBar(params) {
  let userType = localStorage.getItem("type");
  const logOut = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("userName", "");
    localStorage.setItem("type", "");
  };
  return (
    <div>
      <ul>
        {userType && userType == "Call Center" ? (
          ""
        ) : (
          <>
            <li>
              <a className="active" href="/MyDashBoardScreen/">
                Dashboard
              </a>
            </li>
            <li>
              <a href="/myProductManagementScreen/">Urun Yonetimi</a>
            </li>
            <li>
              <a href="/myCityManagementScreen/">Sehir Yonetimi</a>
            </li>
          </>
        )}
        <li>
          <a href="/MyCustomerManagementScreen/">Musteri Yonetimi</a>
        </li>
        <li>
          <a href="/MyCustomerLandingPage/">Musteri Karsilama Ekrani</a>
        </li>
        <li>
          <a href={"/MyOrderAndDetailListScreen/"}>Talep Takip Ekrani</a>
        </li>
        <li className="nav navbar-nav navbar-right logout">
          <a href="/MyLoginScreen" onClick={logOut}>
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
}

export default MyNavBar;
