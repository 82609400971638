import React, { useState, useEffect, useRef } from "react";
import axios, { post } from "axios";
import MyNavBar from "../myNavBar.js";
import MyScreenFooter from "../myScreenFooter.js";
import Style from "../App.css";
import Modal from "react-modal";
import ConvertToBase64 from "../../tools/convertToBase64.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Button, Container, Row, Col } from "react-bootstrap";
import countries from "../const/countries.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MyCityManagementScreen(params) {
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState(0);
  const [plateNumber, setPlateNumber] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [northeast_lat, setNortheast_lat] = useState(0);
  const [northeast_lon, setNortheast_lon] = useState(0);
  const [southwest_lat, setSouthwest_lat] = useState(0);
  const [southwest_lon, setSouthwest_lon] = useState(0);

  let basurl = window.location.hostname;
  let userName = localStorage.getItem("email");
  let companyId = localStorage.getItem("Company");

  function handleSubmit() {
    if (city && country) {
      fetch(`http://${basurl}:9091/api/masterDataAPI/upsertCityAPI`, {
        method: "POST",
        redirect: "follow",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          Id: cityId,
          City: city,
          PlateNumber: plateNumber,
          Country: country,
          lat: lat,
          lon: lon,
          northeast_lat: northeast_lat,
          northeast_lon: northeast_lon,
          southwest_lat: southwest_lat,
          southwest_lon: southwest_lon,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            toast.info("Kaydetme islemi basarili. Sonraki isleme gecebilirsiniz.");
          }
        })
        .catch((err) => console.log(err));
    }
  }
  
  const handleNewRecord = () => {
    setCityId(0);
    setCity("");
    setCountry("");
    setPlateNumber("");
    setLat("");
    setLon("");
    setNortheast_lat("");
    setNortheast_lon("");
    setSouthwest_lat("");
    setSouthwest_lon("");
  };

  function getCities() {
    if (country) {
      const url = `http://${basurl}:9091/api/masterDataAPI/getCitiesAPI`;
      axios
        .get(url, {
          headers: {
            "content-type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          params: {
            CompanyId: companyId,
            CityCode: city,
            Country: country,
          },
        })
        .then((res) => {
          if (res.data.success) {
            setCities(res.data.cities || []);
          }
        });
    } else {
      toast.warn("Sorgu yapabilmek icin ilk ulke secmis olmalisiniz!");
    }
  }

  function handleEditClick(value) {
    console.log("value-->", value);
    const selectedCity = cities.find((city) => city._id == value);
    console.log("selectedCity-->", selectedCity);
    setCityId(value);
    setCity(selectedCity.City);
    setCountry(selectedCity.Country);
    setPlateNumber(selectedCity.PlateNumber);
    setLat(selectedCity.lat);
    setLon(selectedCity.lon);

    setNortheast_lat(selectedCity.northeast_lat);
    setNortheast_lon(selectedCity.northeast_lon);
    setSouthwest_lat(selectedCity.southwest_lat);
    setSouthwest_lon(selectedCity.southwest_lon);
  }

  function renderTableData() {
    return cities.map((city) => {
      const { _id, City, Country, PlateNumber, lat, lon, northeast_lat, northeast_lon, southwest_lat, southwest_lon } =
        city; //destructuring
      return (
        <tr key={_id}>
          <td>
            <Button
              className="btn btn-warning"
              style={{ minHeight: "15px", fontWeight: "bolder", maxWidth: "80px", color: "black" }}
              onClick={() => handleEditClick(_id)}
            >
              <span>Duzenle</span>
            </Button>
          </td>
          <td>{City}</td>
          <td>{Country}</td>
          <td>{PlateNumber}</td>
          <td>{lat}</td>
          <td>{lon}</td>
          <td>{northeast_lat}</td>
          <td>{northeast_lon}</td>
          <td>{southwest_lat}</td>
          <td>{southwest_lon}</td>
        </tr>
      );
    });
  }

  return (
    <div>
      <div className="HeaderSection">
        <ToastContainer position="top-center" newestOnTop={true} pauseOnHover theme="dark" />
        <div className="Form1">
          <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
        </div>
        <form className="Form2">
          <Container fluid>
            <Row>
              <Col md={4}>
                <label>Countr: </label>
                <select
                  name="country"
                  required
                  value={country}
                  onChange={(e) => {
                    console.log("e.target.value-->", e.target.value);
                    setCountry(e.target.value);
                  }}
                  className="form-control"
                >
                  <option value="0" selected>
                    Ulke Seçiniz...
                  </option>
                  {countries.map((data) => (
                    <option key={data.code} value={data.code}>
                      {data.label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={4}>
                <label>City: </label>
                <input
                  type="text"
                  placeholder="City"
                  required
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="form-control"
                />
              </Col>
              <Col md={4}>
                <label>Plate Number: </label>
                <input
                  type="text"
                  placeholder="Plate Number"
                  required
                  name="plateNumber"
                  value={plateNumber}
                  onChange={(e) => setPlateNumber(e.target.value)}
                  className="form-control"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label>Latitude: </label>
                <input
                  type="text"
                  placeholder="Latitude"
                  required
                  name="lat"
                  value={lat}
                  onChange={(e) => setLat(e.target.value)}
                  className="form-control"
                />
              </Col>
              <Col md={4}>
                <label>Longitude: </label>
                <input
                  type="text"
                  placeholder="Longitude"
                  required
                  name="lon"
                  value={lon}
                  onChange={(e) => setLon(e.target.value)}
                  className="form-control"
                />
              </Col>
            </Row>
          </Container>
        </form>
        <div className="Form2">
          <button
            type="button"
            style={{ width: "30%", height: "60px" }}
            onClick={getCities}
            className="btn btn-warning"
          >
            Listele
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            style={{ width: "30%", height: "60px" }}
            onClick={handleSubmit}
            className="btn btn-success"
          >
            Kaydet
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            style={{ width: "30%", height: "60px" }}
            onClick={handleNewRecord}
            className="btn btn-primary"
          >
            Yeni Kayit
          </button>
        </div>
      </div>
      <div>
        <table id="table-to-xls" className="Form1">
          <thead style={{ position: "sticky", top: "290px", zIndex: 2 }}>
            <tr>
              <th>Islem</th>
              <th>Sehir</th>
              <th>Ulke</th>
              <th>Plaka Numarasi(Varsa yoksa Sehir bilgisi)</th>
              <th>Latitude</th>
              <th>Longitude</th>
              <th>En Uc Kuzey Latitude</th>
              <th>En Uc Kuzey Longitude</th>
              <th>En Uc Guney Latitude</th>
              <th>En Uc Guney Longitude</th>
            </tr>
          </thead>
          <tbody>{renderTableData()}</tbody>
        </table>
      </div>

      <div className="FooterSection">
        <MyScreenFooter
          screenName="Urun Tanımlama Ekranı"
          userNameFooter={userName}
          conpanyIDFooter={companyId}
        ></MyScreenFooter>
      </div>
    </div>
  );
}

export default MyCityManagementScreen;
