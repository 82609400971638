import React, { useEffect } from "react";
import style from "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import MyLoginScreen from "./ABSCentralBrain/en/myLoginScreen.js";
import MyCustomerLandingPage from "./ABSCentralBrain/en/myCustomerLandingPage.js";
import MyCurrentCustomerManagementScreen from "./ABSCentralBrain/en/myCurrentCustomerManagementScreen.js";
import MyDashBoardScreen from "./ABSCentralBrain/en/myDashBoardScreen";
//Master data 
import MyProductManagementScreen from "./ABSCentralBrain/en/masterData/myProductManagementScreen.js";
import MyCityManagementScreen from "./ABSCentralBrain/en/masterData/myCityManagementScreen.js";
import MyCustomerManagementScreen from "./ABSCentralBrain/en/masterData/myCustomerManagementScreen.js";
import MyOrderAndDetailListScreen from "./ABSCentralBrain/en/myOrderAndDetailListScreen.js";

import { Route, BrowserRouter as Router, Switch, BrowserRouter } from "react-router-dom";
import Style from "./ABSCentralBrain/en/css/bootstrap.min.css";

const userLangugae = window.navigator.language === "tr" ? "tr" : "en";
// console.log(window.navigator);

function App() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `We are here to help you.`;
  }, []);
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path="/" exact component={MyLoginScreen} />
          <Route path="/MyLoginScreen" component={MyLoginScreen} />
          <Route path="/MyDashBoardScreen"exact component={MyDashBoardScreen} />
          <Route path="/MyProductManagementScreen/" exact component={MyProductManagementScreen} />
          <Route path="/MyCityManagementScreen/" exact component={MyCityManagementScreen} />
          <Route path="/MyCustomerLandingPage/:PhoneNumber?/:UserCode?" exact component={MyCustomerLandingPage} />
          <Route path="/MyCurrentCustomerManagementScreen/:PhoneNumber?/:UserCode?" exact component={MyCurrentCustomerManagementScreen} />
          <Route path="/MyCustomerManagementScreen"exact component={MyCustomerManagementScreen} />
          <Route path="/MyOrderAndDetailListScreen/" component={MyOrderAndDetailListScreen} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App; //exporting a component make it reusable and this is the beauty of react
