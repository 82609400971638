import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import MyNavBar from "./myNavBar.js";
import MyScreenFooter from "./myScreenFooter.js";
import SearchPanel from "./components/SearchPanel";
import SearchResult from "./components/SearchResult";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Container, Row, Col, Card, Button } from "react-bootstrap";
let basurl = window.location.hostname;
let userName = localStorage.getItem("userName");
let companyId = localStorage.getItem("CompanyID");
let operationCenterID = localStorage.getItem("OperationCenterID");
let screenHeight = window.innerWidth;

export default function MyCurrentCustomerManagementScreen(params) {
  let { PhoneNumber, UserCode } = useParams();

  const [phoneNumber, setPhoneNumber] = useState(PhoneNumber);
  const [nameSurname, setNameSurname] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleEnterKeyPress = (e) => {
    setIsLoading(true);
    if (e.key === "Enter") {
      fetch(`http://${basurl}:9091/api/ManagementAPI/crm/search-for-customer`, {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          nameSurname: nameSurname,
          orderNumber: orderNumber,
        }),
      })
        .then((res) => {
          if (res.status === 498) {
            window.location.href = `/MyLoginScreen/`;
            localStorage.setItem("token", "");
            localStorage.setItem("userName", "");
          }
          return res.json();
        })
        .then((data) => {
          if (!data.customers) {
            toast.warning("Belirtilen bilgilere ait simtemde cari bulunamadi. Lutfen kontrol edip tekrar deneyin!");
          }
          setCustomers(data.customers);
        })
        .catch((err) => console.log(err));
    }
    setIsLoading(false);
  };

  return (
    <div className="master">
      <div className="Form1">
        <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
      </div>
      <ToastContainer draggable pauseOnHover newestOnTop={true} position="top-center" />
      <Container fluid style={{ backgroundColor: "#7E4B26", minHeight: "10px" }}>
        <Row>
          <Col sm={12}></Col>
        </Row>
      </Container>
      <SearchPanel
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        nameSurname={nameSurname}
        setNameSurname={setNameSurname}
        orderNumber={orderNumber}
        setOrderNumber={setOrderNumber}
        handleEnterKeyPress={handleEnterKeyPress}
        isLoading={isLoading}
      ></SearchPanel>
      <Container fluid style={{ backgroundColor: "#7E4B26", minHeight: "10px" }}>
        <Row>
          <Col sm={12}></Col>
        </Row>
      </Container>
      {customers && customers.length ? <SearchResult customers={customers} user={userName}></SearchResult> : ""}
      <div className="Form1 footer" style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <MyScreenFooter
          screenName="Customer Searching Page"
          userNameFooter={userName}
          conpanyIDFooter={companyId}
        ></MyScreenFooter>
      </div>
    </div>
  );
}
