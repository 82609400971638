import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import '../css/crm.css'

function SearchPanel(params) {
  const { handleEnterKeyPress, nameSurname, setNameSurname, phoneNumber, setPhoneNumber, orderNumber, setOrderNumber } =
    params;

  return (
    <div>
      <Container fluid>
        <Row className="row">
          <Col lg="4">
            {" "}
            <Row>
              <label>Adi Soyadi</label>
            </Row>
            <Row>
              <input
                type="text"
                placeholder="Adi Soyadi"
                required
                name="nameSurname"
                value={nameSurname}
                onChange={(e) => setNameSurname(e.target.value)}
                className="square border border-warning"
                onKeyDown={handleEnterKeyPress}
              />
            </Row>
          </Col>
          <Col lg="4">
            <Row>
              <label>Telefon Numarasi</label>
            </Row>
            <Row>
              <input
                type="text"
                placeholder="Telefon Numarasi"
                required
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="square border border-warning"
                onKeyDown={handleEnterKeyPress}
              />
            </Row>
          </Col>
          <Col lg="4">
            <Row>
              <label>Siparis No</label>
            </Row>
            <Row>
              <input
                type="text"
                placeholder="Siparis No"
                required
                name="orderNumber"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                className="square border border-warning"
                onKeyDown={handleEnterKeyPress}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SearchPanel;
