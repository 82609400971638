import React, { useState, useEffect, useRef } from "react";
import axios, { post } from "axios";
import MyNavBar from "../myNavBar.js";
import MyScreenFooter from "../myScreenFooter.js";
import "../css/crm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col } from "react-bootstrap";
import ReactLoading from "react-loading";

import CustomerPanel from "../components/CustomerPanel";
import CustomerAddressPanel from "../components/CustomerAddressPanel";
import CustomerPhonePanel from "../components/CustomerPhonePanel";
import CustomerEmailPanel from "../components/CustomerEmailPanel";
import CustomerCommentPanel from "../components/CustomerCommentPanel";

function MyCustomerManagementScreen(params) {
  const [code, setCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState([""]);
  const [email, setEmail] = useState([]);
  const [active, setActive] = useState(false);
  const [type, setType] = useState("");
  const [customers, setCustomers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [comments, setComments] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [customerId, setCustomerId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [popupCustomers, setPopupCustomers] = useState([]);
  const [orders, setOrders] = useState([]);

  let basurl = window.location.hostname;
  let userName = localStorage.getItem("userName");
  let companyId = localStorage.getItem("Company");
  let userEmail = localStorage.getItem("email");
  //005302889412
  useEffect(() => {
    if (customers && customers.length) {
      setCustomerId(customers[0]._id);
      setPhoneNumber(customers[0].CustomerNumber);
      setEmail(customers[0].CustomerEmail);
      setType(customers[0].Type);
      setCode(customers[0].Code || customers[0].ReferenceNumber);
      setActive(customers[0].Active);
      setAddresses({ customerId: customers[0]._id, Addresses: customers[0].Addresses });
      setComments(customers[0].Comments);
    }
  }, [customers]);

  useEffect(() => {
    setIsLoading(true);
    const url1 = `http://${basurl}:9091/api/masterDataAPI/getCustomersAPI`;
    axios
      .get(url1, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((res1) => {
        const customers = res1.data;
        setAllCustomers(customers);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, []);

  const handleEnterKeyPress = async (e) => {
    if (!e.key || e.key === "Enter") {
      setIsLoading(true);
      fetch(`http://${basurl}:9091/api/ManagementAPI/crm/search-for-customer`, {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
          system: "callCenter",
        },
        body: JSON.stringify({
          code: code,
          phoneNumber: phoneNumber[0],
          customerId: customerId,
          email: email,
        }),
      })
        .then((res) => {
          if (res.status === 498) {
            window.location.href = `/MyLoginScreen/`;
            localStorage.setItem("token", "");
            localStorage.setItem("userName", "");
          }
          return res.json();
        })
        .then((data) => {
          if (!data.customers) {
            toast.warning("Belirtilen bilgilere ait simtemde cari bulunamadi. Lutfen kontrol edip tekrar deneyin!");
          }
          setCustomers(data.customers);
          setCustomerId(data.customers[0]._id);
          setPopupCustomers([]);
          fetch(`http://${basurl}:9091/api/ManagementAPI/crm`, {
            method: "POST",
            redirect: "follow",
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem("token"),
            },
            body: JSON.stringify({
              phoneNumber: phoneNumber[0],
              userCode: userEmail,
            }),
          })
            .then((res) => res.json())
            .then((res2) => {
              if (!res2.success) {
                toast.warning(`${phoneNumber[0]} ${res2.message}`);
              } else {
                setPopupCustomers(res2.customers);
                setOrders(res2.orders);
              }
              setIsLoading(false);
            });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (warningMessage) {
      toast.warning(warningMessage);
    }
  }, [showWarning]);

  return (
    <div>
      <div className="Form1">
        <MyNavBar userName={userName} conpanyID={companyId}></MyNavBar>
      </div>
      <ToastContainer draggable pauseOnHover newestOnTop={true} position="top-center" />
      {isLoading ? (
        <div className="loading-spinner">
          <ReactLoading className="spinner" type="spinningBubbles" color="#7E4B26" height="40%" width="10%" />
        </div>
      ) : (
        <div>
          <CustomerPanel
            handleEnterKeyPress={handleEnterKeyPress}
            code={code}
            setCode={setCode}
            customerId={customerId}
            setCustomerId={setCustomerId}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            email={email}
            setEmail={setEmail}
            active={active}
            setActive={setActive}
            type={type}
            setType={setType}
            setShowWarning={setShowWarning}
            setWarningMessage={setWarningMessage}
            setAddresses={setAddresses}
            setIsLoading={setIsLoading}
            setComments={setComments}
            isLoading={isLoading}
            allCustomers={allCustomers}
            popupCustomers={popupCustomers}
            userEmail={userEmail}
            orders={orders}
          ></CustomerPanel>
          <Container fluid style={{ backgroundColor: "#7E4B26", minHeight: "5px", marginTop: "20px" }}>
            <Row>
              <Col sm={12}></Col>
            </Row>
          </Container>
          <div>
            <Container fluid className="border border-warning">
              <Row>
                <Col lg="12" className="border border-primary">
                  <CustomerAddressPanel
                    customerId={customerId}
                    addresses={addresses}
                    setShowWarning={setShowWarning}
                    setWarningMessage={setWarningMessage}
                    handleEnterKeyPress={handleEnterKeyPress}
                  ></CustomerAddressPanel>
                </Col>
              </Row>
            </Container>
            <Container fluid style={{ backgroundColor: "#7E4B26", minHeight: "5px", marginTop: "20px" }}>
              <Row>
                <Col sm={12}></Col>
              </Row>
            </Container>
            <Container fluid className="border border-warning">
              <Row>
                <Col lg="6" className="border border-primary">
                  <CustomerPhonePanel
                    customerId={customerId}
                    phones={customers && customers.length ? phoneNumber : []}
                    setShowWarning={setShowWarning}
                    setWarningMessage={setWarningMessage}
                    handleEnterKeyPress={handleEnterKeyPress}
                  ></CustomerPhonePanel>
                </Col>
                <Col lg="6" className="border border-primary">
                  <CustomerEmailPanel
                    customerId={customerId}
                    emails={customers && customers.length ? email : []}
                    setShowWarning={setShowWarning}
                    setWarningMessage={setWarningMessage}
                    handleEnterKeyPress={handleEnterKeyPress}
                  ></CustomerEmailPanel>
                </Col>
              </Row>
            </Container>
            <Container fluid style={{ backgroundColor: "#7E4B26", minHeight: "5px", marginTop: "20px" }}>
              <Row>
                <Col sm={12}></Col>
              </Row>
            </Container>
            <Container fluid className="border border-warning">
              <Row>
                <Col lg="6" className="border border-primary">
                  <CustomerCommentPanel
                    customerId={customerId}
                    comments={comments}
                    setShowWarning={setShowWarning}
                    setWarningMessage={setWarningMessage}
                    handleEnterKeyPress={handleEnterKeyPress}
                  ></CustomerCommentPanel>
                </Col>
                <Col lg="6" className="border border-primary"></Col>
              </Row>
            </Container>
          </div>
        </div>
      )}

      <div className="Form1" style={{ position: "relative", bottom: 0, width: "100%" }}>
        <MyScreenFooter
          screenName="Müşteri Yönetim Ekranı"
          userNameFooter={userName}
          conpanyIDFooter={companyId}
        ></MyScreenFooter>
      </div>
    </div>
  );
}

export default MyCustomerManagementScreen;
