import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/crm.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import Modal from "react-modal";
import CommentPageModal from "./CommentPageModal";
let basurl = window.location.hostname;

function CustomerCommentPanel(params) {
  const { comments, customerId, setShowWarning, setWarningMessage,handleEnterKeyPress } = params;
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState("");

  const handleAddComment = () => {
    if (!customerId) {
      setShowWarning(true);
      setWarningMessage("Musteri secilmemis lutfen kontrol edip tekrar deneyin!");
    } else {
      setShowCommentModal(true);
    }
  };

  function handleAddCommentButton() {
    setShowCommentModal(false);
    setComment("");
    fetch(`http://${basurl}:9091/api/ManagementAPI/crm/add-comment-customer`, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        customerId: customerId,
        comment: comment,
      }),
    })
      .then((res) => {
        if (res.status === 498) {
          window.location.href = `/MyLoginScreen/`;
          localStorage.setItem("token", "");
          localStorage.setItem("userName", "");
        }
        return res.json();
      })
      .then((data) => {
        handleEnterKeyPress({ e: { key: "Enter" } });
        console.log(data);
      })
      .catch((err) => console.log(err));
  }

  const renderTableData = () => {
    return (
      comments &&
      comments.map((customer, index) => {
        const { _id, Comment, EnteredBy, Date } = customer;
        return (
          <tr key={_id}>
            <td>
              <input style={{ margin: "10px" }} type="checkbox" value={_id} name="_id" />
              &nbsp;
            </td>
            <td>{Comment}</td>
            <td>{EnteredBy}</td>
            <td>{moment(Date).format("DD.MM.YYYY HH:mm")}</td>
          </tr>
        );
      })
    );
  };

  const handleCommentCloseButton=()=>{
    setShowCommentModal(false);
    setComment("");
  }
  return (
    <div>
      <Container fluid>
        <Row className="row">
          <Col lg="12">
            {" "}
            <Row>
              <h4>Musteri Yorum Detayi</h4>
            </Row>
            <Row>
              <table id="table-to-xls" className="Form1" style={{ zIndex: -1 }}>
                <tr>
                  <th>Seç</th>
                  <th>Yorum</th>
                  <th>Kaydeden</th>
                  <th>Kayıt Tarihi</th>
                </tr>
                <tbody>{renderTableData()}</tbody>
              </table>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="1">
            <button
              style={{ width: "fit-content", marginLeft: -10 }}
              className="btn btn-primary"
              onClick={handleAddComment}
            >
              Yorum Ekle
            </button>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showCommentModal}
        ariaHideApp={false}
        onRequestClose={handleCommentCloseButton}
        style={{
          overlay: { backgroundColor: "#8ea6b4", zIndex: "99" },
          content: { color: "#984a59", backgroundColor: "#e7eff3", height: "35%" },
        }}
      >
        <CommentPageModal
          comment={comment}
          setComment={setComment}
          handleAddCommentButton={handleAddCommentButton}
        ></CommentPageModal>
      </Modal>
    </div>
  );
}

export default CustomerCommentPanel;
