import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/crm.css";
import moment from "moment";
import CustomerAddressPanelModal from "./CustomerAddressPanelModal";
import Modal from "react-modal";

function CustomerAddressPanel(params) {
  const { customerId, addresses, setWarningMessage, setShowWarning,handleEnterKeyPress } = params;
  const [selected, setSelected] = useState(0);
  const [showAddAddress, setShowAddAddress] = useState(false);

  const handleAddAddress = () => {
    if (addresses && !addresses.customerId) {
      setShowWarning(true);
      setWarningMessage("Musteri secilmemis lutfen kontrol edip tekrar deneyin!");
    } else {
      setShowAddAddress(true);
    }
  };

  function handleSelection(e) {
    let selectedID = e.target.value;
    console.log(selectedID);
    addresses.map((address) => {
      if ((address["_id"] === selectedID) & (address["selected"] === 0)) {
        address["selected"] = 1;
      } else if ((address["_id"] === selectedID) & (address["selected"] === 1)) {
        address["selected"] = 0;
      }
    });
  }

  const renderTableData = () => {
    return (
      addresses &&
      addresses.Addresses &&
      addresses.Addresses.map((address, index) => {
        const { _id, Active, Country, City, Town, PostCode, GateNumber, Street, AddressText, EnteredBy, Date } =
          address; //destructuring
        return (
          <tr key={address?._id}>
            <td>
              <input
                type="checkbox"
                selected={selected}
                style={{ margin: "10px" }}
                onChange={handleSelection}
                value={address?._id}
                name="_id"
              />
              &nbsp;
            </td>
            <td>{Country}</td>
            <td>{City}</td>
            <td>{Town}</td>
            <td style={{ fontSize: "15px", fontWeight: "600", color: "#A52A2A" }}>{PostCode}</td>
            <td>{GateNumber}</td>
            <td>{Street}</td>
            <td style={{ fontSize: "15px", fontWeight: "600", color: "#A52A2A" }}>{AddressText}</td>
            <td>{EnteredBy}</td>
            <td>{moment(Date).format("DD.MM.YYYY HH:mm")}</td>
            <td>{Active ? "Evet" : "Hayir"}</td>
          </tr>
        );
      })
    );
  };

  return (
    <div>
      <Container fluid>
        <Row className="row">
          <Col lg="12">
            {" "}
            <Row>
              <h4>Musteri Adres Detayi</h4>
            </Row>
            <Row>
              <table id="table-to-xls" className="Form1" style={{ zIndex: -1 }}>
                <tr>
                  <th>Seç</th>
                  <th>Ulke</th>
                  <th>İl</th>
                  <th>İlçe</th>
                  <th>Posta Kodu</th>
                  <th>Kapi No</th>
                  <th>Cadd/Mahalle</th>
                  <th>Adres</th>
                  <th>Kaydeden</th>
                  <th>Kayıt Tarihi</th>
                  <th>Aktif</th>
                </tr>
                <tbody>{renderTableData()}</tbody>
              </table>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="1">
            <button
              style={{ width: "fit-content", marginLeft: -48}}
              className="btn btn-primary"
              onClick={handleAddAddress}
            >
              Adres Ekle
            </button>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={showAddAddress}
        ariaHideApp={false}
        onRequestClose={() => setShowAddAddress(false)}
        style={{
          overlay: { backgroundColor: "#8ea6b4", zIndex: "99" },
          content: { color: "#984a59", backgroundColor: "#e7eff3", height: "80%" },
        }}
      >
        <CustomerAddressPanelModal
          customerId={customerId}
          setShowAddAddress={setShowAddAddress}
          setWarningMessage={setWarningMessage}
          setShowWarning={setShowWarning}
          handleEnterKeyPress={handleEnterKeyPress}
        ></CustomerAddressPanelModal>
      </Modal>
    </div>
  );
}

export default CustomerAddressPanel;
