  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#7E4B26",
      minHeight: "35px",
      minWidth: "300px",
      height: "35px",
      zIndex: 70,
      boxShadow: state.isFocused ? null : null,
      marginTop:"10px"
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      backgroundColor: "#7E4B26",
    }),
  };

  export default customStyles;