import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/crm.css";
import customStyles from "../css/customerStyle";
import countries from "../const/countries";
import axios, { post } from "axios";
import Modal from "react-modal";
import LandingPageModal from "./LandingPageModal";

let basurl = window.location.hostname;

function CustomerPanel(params) {
  const {
    handleEnterKeyPress,
    code,
    setCode,
    customerId,
    setCustomerId,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    active,
    setActive,
    type,
    setType,
    setShowWarning,
    setWarningMessage,
    setAddresses,
    setComments,
    isLoading,
    allCustomers,
    popupCustomers,
    userEmail,
    orders
  } = params;

  const handleClearFormButton = () => {
    setIsNewRecord(!isNewRecord);
    setCustomerId(0);
    setPhoneNumber([]);
    setEmail([]);
    setActive(true);
    setType(0);
    setAddresses([]);
    setComments([]);
  };


  const [nameSurname, setNameSurname] = useState("");
  const [isNewRecord, setIsNewRecord] = useState(false);

  const [showWeKnowPhoneNumber, setWeKnowPhoneNumber] = useState(false);

  const getMeToOrderListScreen = () => {
    window.location.href = `/MyCustomerManagementScreen/`;
  };

  // console.log(Number.isInteger("tt"));
  const handleChangePhone = (e) => {
    if (phoneNumber.length && Number.isNaN(+e.target.value)) {
      setShowWarning(true);
      alert("Girilen deger numara olmalidir. Lutfen kontrol edip tekrar deneyin!!!");
    } else {
      setPhoneNumber([e.target.value]);
    }
  };

  const types = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
  ];

  const [country, setCountry] = useState(null);

  const handleUpsertCustomerButton = () => {
    if (Number.isNaN(+phoneNumber[0])) {
      setShowWarning(true);
      setWarningMessage("Musteri telefon numarasi numeric olarak girilmemis lutfen kontrol edip tekrar deneyin!");
    } else if (!phoneNumber.reduce((total, e) => (e !== "" ? 1 : 0) + total, 0) && !customerId && !nameSurname) {
      setShowWarning(true);
      setWarningMessage("Musteri telefon numarasi ve adi girilmemis lutfen kontrol edip tekrar deneyin!");
    } else {
      fetch(`http://${basurl}:9091/api/ManagementAPI/crm/upsert-customer`, {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          customer: {
            CustomerId: customerId,
            Code: code,
            Type: type,
            NameSurname: nameSurname,
            CustomerNumber: phoneNumber,
            CustomerEmail: email,
            Active: active,
          },
        }),
      })
        .then((res) => {
          if (res.status === 498) {
            window.location.href = `/MyLoginScreen/`;
            localStorage.setItem("token", "");
            localStorage.setItem("userName", "");
          }
          return res.json();
        })
        .then((data) => {
          if (data.success) {
            toast.success(data?.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // customer full detail on popup screen
  const handleShowAllDetailButton = () => {
    if (!phoneNumber[0]) {
      toast.warning("Musteri telefon numarasi girilmemis. lutfen kontrol edip tekrar deneyin!");
    } else if (popupCustomers.length == 0) {
      toast.warning("Girilen numaraya ait kayit bulunamadi. lutfen kontrol edip tekrar deneyin!");
    } else {
      setWeKnowPhoneNumber(true);
    }
  };

  return (
    <div>
      <ToastContainer draggable pauseOnHover newestOnTop={true} position="top-center" />
      <Container fluid>
        <Row>
          <Col lg="10">
            <Row className="row">
              <Col lg="3">
                {" "}
                <Row>
                  <label>Musteri Kodu</label>
                </Row>
                <Row>
                  <input
                    type="text"
                    placeholder="Kodu"
                    required
                    name="code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className="square border border-warning"
                    onKeyDown={handleEnterKeyPress}
                  />
                </Row>
              </Col>

              {isNewRecord ? (
                <Col lg="3">
                  <Row>
                    <label>Ad/Tam Unvani</label>
                  </Row>
                  <Row>
                    <input
                      type="text"
                      placeholder="Ad/Tam Unvani"
                      required
                      name="nameSurname"
                      value={nameSurname}
                      onChange={(e) => setNameSurname(e.target.value)}
                      className="square border border-warning"
                      onKeyDown={handleEnterKeyPress}
                    />
                  </Row>
                </Col>
              ) : (
                <Col lg="3">
                  <Row>
                    <label>Ad/Tam Unvani</label>
                  </Row>
                  <Row>
                    <Select
                      name="customerId"
                      required
                      filterOption={createFilter({ ignoreAccents: false })}
                      onChange={(e) => {
                        setCustomerId(e.value);
                      }}
                      options={allCustomers}
                      styles={customStyles}
                      value={allCustomers.find((c) => c.value == customerId)}
                      defaultValue={{ label: "Musteri adi/unvani yaziniz!!!", value: 0 }}
                    />
                  </Row>
                </Col>
              )}

              {isNewRecord ? (
                <Col lg="3">
                  <Row>
                    <label>Telefon Numarasi Ulke</label>
                  </Row>
                  <Row>
                    <Select
                      name="country"
                      required
                      onChange={(e) => {
                        setCountry(e.value);
                        setPhoneNumber([e.dial]);
                      }}
                      options={countries}
                      styles={customStyles}
                      value={countries.find((c) => c.value == country)}
                      defaultValue={{ label: "Ulke Secin", value: 0 }}
                    />
                  </Row>
                </Col>
              ) : (
                ""
              )}
              <Col lg="3">
                <Row>
                  <label>Telefon Numarasi</label>
                </Row>
                <Row>
                  <input
                    id="phone"
                    type="text"
                    placeholder="Telefon Numarasi"
                    pattern="^\d*"
                    required
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => handleChangePhone(e)}
                    className="square border border-warning"
                    onKeyDown={handleEnterKeyPress}
                  />
                </Row>
              </Col>
              <Col lg="3">
                <Row>
                  <label>Email</label>
                </Row>
                <Row>
                  <input
                    type="text"
                    placeholder="Email"
                    required
                    name="email"
                    value={email || ""}
                    onChange={(e) => setEmail([e.target.value])}
                    className="square border border-warning"
                    onKeyDown={handleEnterKeyPress}
                  />
                </Row>
              </Col>
              <Col lg="3">
                {" "}
                <Row>
                  <label>Musteri Tipi</label>
                </Row>
                <Row>
                  <Select
                    name="type"
                    required
                    onChange={(e) => setType(e.value)}
                    options={types}
                    styles={customStyles}
                    value={types.find((t) => t.value == type)}
                    defaultValue={{ label: "Tip Secin", value: 0 }}
                  />
                </Row>
              </Col>
              <Col lg="3">
                <Row>
                  <label>Aktif?</label>
                </Row>
                <Row>
                  <input
                    style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "20px" }}
                    type="checkbox"
                    required
                    checked={active}
                    name="active"
                    value={active}
                    onChange={(e) => {
                      setActive(typeof e.target.value == "string" && e.target.value == "false" ? true : false);
                    }}
                    className="square border border-warning"
                    onKeyDown={handleEnterKeyPress}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="12" lg="2">
            <Row>
              <Col>
                <button
                  style={{ minWidth: "150px", marginLeft: -10, color: "#473122" }}
                  className="btn btn-warning"
                  onClick={handleEnterKeyPress}
                  disabled={isLoading}
                >
                  Sorgula
                </button>
              </Col>
            </Row>

            <Row style={{ marginTop: "5px" }}>
              <Col>
                <button
                  style={{
                    minWidth: "150px",
                    marginLeft: -10,
                    color: "#473122",
                    backgroundColor: "#ECCCB2",
                  }}
                  className="btn btn-danger"
                  onClick={handleShowAllDetailButton}
                >
                  Tum Detayi Goster
                </button>
              </Col>
            </Row>
            <Row style={{ marginTop: "5px" }}>
              <Col>
                <button
                  style={{ minWidth: "150px", marginLeft: -10, color: "#473122" }}
                  className="btn btn-danger"
                  onClick={handleClearFormButton}
                >
                  {isNewRecord ? "Sorgulama Ekrani" : "Ekrani Temizle"}
                </button>
              </Col>
            </Row>
            <Row style={{ marginTop: "5px" }}>
              <Col>
                <button
                  style={{ minWidth: "150px", marginLeft: -10, color: "#473122" }}
                  className="btn btn-info"
                  onClick={handleUpsertCustomerButton}
                >
                  Musteri Ekle
                </button>
              </Col>
            </Row>
            <Row style={{ marginTop: "5px" }}>
              <Col>
                <button
                  style={{ minWidth: "150px", marginLeft: -10, color: "#473122" }}
                  className="btn btn-success"
                  onClick={handleUpsertCustomerButton}
                >
                  Musteri Guncelle
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          ariaHideApp={false}
          isOpen={showWeKnowPhoneNumber}
          onRequestClose={() => setWeKnowPhoneNumber(false)}
          style={{
            overlay: { backgroundColor: "#8ea6b4", zIndex: 1000 },
            content: { color: "#984a59", backgroundColor: "#e7eff3", height: "85%" },
          }}
        >
          <LandingPageModal
            PhoneNumber={phoneNumber}
            UserCode={userEmail}
            getMeToOrderListScreen={getMeToOrderListScreen}
            customers={popupCustomers}
            orders={orders}
          ></LandingPageModal>
        </Modal>
      </Container>
    </div>
  );
}

export default CustomerPanel;
