import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/crm.css";
import Modal from "react-modal";
import CommentPageModal from "./CommentPageModal";
let basurl = window.location.hostname;

function SearchResult(params) {
  const { customers, user } = params;
  const [selected, setSelected] = useState(0);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [comment, setComment] = useState("");

  function handleCommentButton(customerId) {
    setShowCommentModal(true);
    setCustomerId(customerId);
    console.log("customerId--->", customerId);
  }

  function handleAddCommentButton() {
    setShowCommentModal(false);
    fetch(`http://${basurl}:9091/api/ManagementAPI/crm/add-comment-customer`, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        customerId: customerId,
        comment: comment,
      }),
    })
      .then((res) => {
        if (res.status === 498) {
          window.location.href = `/MyLoginScreen/`;
          localStorage.setItem("token", "");
          localStorage.setItem("userName", "");
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));

    setCustomerId(customerId);

    console.log("comment--->", comment);
  }

  function renderTableData() {
    return customers.map((customer, index) => {
      const { CustomerName, CustomerNumber, CustomerEmail, ReferenceNumber, Type, Addresses, Active } = customer;
      return (
        <tr key={customer?._id} style={{ fontSize: "17px", fontWeight: "900" }}>
          <td>
            <input
              type="checkbox"
              selected={selected}
              style={{ margin: "10px" }}
              onChange={handleSelection}
              value={customer?._id}
              name="index"
            />
            &nbsp;
          </td>
          <td>
            <button type="button" onClick={() => handleCommentButton(customer?._id)}>
              Add
            </button>
          </td>
          <td>{CustomerName}</td>
          <td style={{ width: "30%" }}>{CustomerNumber}</td>
          <td>{CustomerEmail}</td>
          <td>{Type}</td>
          <td>{ReferenceNumber}</td>
          <td>
            {[...new Set(Addresses.map((add) => add.City && add.City))].map((e, i) => (
              <React.Fragment key={i}>
                {i + 1 + "-" + e}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td>
            {[...new Set(Addresses.map((add) => add.Country && add.Country))].map((e, i) => (
              <React.Fragment key={i}>
                {i + 1 + "-" + e}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td>
            {[...new Set(Addresses.map((add) => add.AddressText))].map((e, i) => {
              if (e) {
                return (
                  <React.Fragment key={i}>
                    {i + 1 + "-" + e}
                    <br />
                  </React.Fragment>
                );
              }
            })}
          </td>
          <td>
            <input
              type="checkbox"
              style={{ minHeight: "20px", margin: "10px" }}
              onChange={() => 1}
              checked={Active ? 1 : 0}
            />
            &nbsp;
          </td>
        </tr>
      );
    });
  }

  function handleSelection(e) {
    let selectedID = e.target.value;
    customers.map((customer) => {
      if ((customer["_id"] === selectedID) & (customer["selected"] === 0)) {
        customer["selected"] = 1;
        console.log(customer);
      } else if ((customer["_id"] === selectedID) & (customer["selected"] === 1)) {
        customer["selected"] = 0;
      }
    });
  }

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <table id="table-to-xls" className="Form1">
              <thead>
                <tr>
                  <th>Seç</th>
                  <th>Yorum</th>
                  <th>Musteri</th>
                  <th>Telefon Numarasi</th>
                  <th>Email</th>
                  <th>Sinifi</th>
                  <th>Ref Number</th>
                  <th>Sehir</th>
                  <th>Ulke</th>
                  <th>Adres</th>
                  <th>Aktif?</th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showCommentModal}
        ariaHideApp={false}
        onRequestClose={() => setShowCommentModal(false)}
        style={{
          overlay: { backgroundColor: "#8ea6b4" },
          content: { color: "#984a59", backgroundColor: "#e7eff3", height: "35%" },
        }}
      >
        <CommentPageModal
          comment={comment}
          setComment={setComment}
          handleAddCommentButton={handleAddCommentButton}
        ></CommentPageModal>
      </Modal>
    </div>
  );
}

export default SearchResult;
