//post data
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Style from "./App.css";
import Logo from "../imgs/nareppo.png";
import MD5 from "crypto-js/md5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MyLoginScreen(params) {
  const [userName, setUserName] = useState();
  const [passWord, setPassWord] = useState();
  const [apiResult, setApiResult] = useState(99);
  //absadmin@abstransport.co.uk 1235@_abs_2020* 39e89eb6b9fa795df8b33686dae76e04
  function getMyCredential(event) {
    event.preventDefault();
    let basurl = window.location.hostname;

    const url1 = `http://${basurl}:9091/api/getAccessToken`;
    axios
      .get(url1, {
        params: {
          email: userName,
          password: MD5(passWord).toString(),
        },
      })
      .then((res1) => {
        if (res1.status) {
          window.location.href = res1.data.type=="Call Center"?`/MyCustomerManagementScreen/`:`/MyDashBoardScreen/`;
          localStorage.setItem("token", res1.data.token);
          localStorage.setItem("type", res1.data.type);
          localStorage.setItem("userName", res1.data.name);
          localStorage.setItem("email", res1.data.email);
        }else{
          toast.error('There is an error!')
        }
      })
      .catch((err)=>{
        toast.error("There is a problem on login process, please contact system administration.");
      })
  }
  return (
    <div clasName="FormLogin">
      <ToastContainer draggable pauseOnHover newestOnTop={true} position="top-center" />
      <div className="bg-img">
        <div class="bird-container bird-container--one">
          <div class="bird bird--one"></div>
        </div>
        <div class="bird-container bird-container--two">
          <div class="bird bird--two"></div>
        </div>
        <div class="bird-container bird-container--three">
          <div class="bird bird--three"></div>
        </div>
        <div class="bird-container bird-container--four">
          <div class="bird bird--four"></div>
        </div>

        <form className="container">
          <div class="clearfix float-my-children">
            <img src={Logo} style={{ height: "100px", width: "100px" }} />
          </div>
          <br></br>
          <input
            type="text"
            name="userName"
            onChange={(e) => setUserName(e.target.value)}
            placeHolder="Emailinizi Giriniz"
            required
          />{" "}
          <br></br>
          <input
            type="password"
            name="passWord"
            onChange={(e) => setPassWord(e.target.value)}
            placeHolder="Şifrenizi Giriniz"
            required
          ></input>{" "}
          <br></br>
          <button type="submit" onClick={getMyCredential} value="Submit" className="btn">
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default MyLoginScreen;
