import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/crm.css";
import Modal from "react-modal";
import CustomerEmailPanelModal from './CustomerEmailPanelModal'

function CustomerEmailPanel(params) {
  const { customerId,emails,setShowWarning, setWarningMessage,handleEnterKeyPress  } = params;
  const [selected, setSelected] = useState(0);
  const[showEmailModal,setShowEmailModal]=useState(false);

  const handleAddEmail = () => {
    if (!customerId) {
      setShowWarning(true);
      setWarningMessage("Musteri secilmemis lutfen kontrol edip tekrar deneyin!");
    } else {
      setShowEmailModal(true);
    }
  };

  const renderTableData = () => {
    return (
      emails.map((email, index) => {
        return (
          <tr key={index}>
            <td>{email}</td>
          </tr>
        );
      })
    );
  };

  return (
    <div>
      <Container fluid>
        <Row className="row">
          <Col lg="12">
            {" "}
            <Row>
              <h4>Musteri Email Detayi</h4>
            </Row>
            <Row>
              <table id="table-to-xls" className="Form1" style={{ zIndex: -1 }}>
                <tr>
                  <th>Email</th>
                </tr>
                <tbody>{renderTableData()}</tbody>
              </table>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="1">
            <button
              style={{ width: "fit-content", marginLeft: -10 }}
              className="btn btn-primary"
              onClick={handleAddEmail}
            >
              Email Ekle
            </button>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={showEmailModal}
        ariaHideApp={false}
        onRequestClose={() => setShowEmailModal(false)}
        style={{
          overlay: { backgroundColor: "#8ea6b4", zIndex: "99" },
          content: { color: "#984a59", backgroundColor: "#e7eff3", height: "30%" },
        }}
      >
        <CustomerEmailPanelModal
          customerId={customerId}
          setShowEmailModal={setShowEmailModal}
          setWarningMessage={setWarningMessage}
          setShowWarning={setShowWarning}
          handleEnterKeyPress={handleEnterKeyPress}
        ></CustomerEmailPanelModal>
      </Modal>
    </div>
  );
}

export default CustomerEmailPanel;
