import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default class InvoicePrintOut extends React.Component {
  render() {
    return (
      <div>
        <div
          style={{
            position: "fixed",
            top: "50",
            left: "0",
            width: "400px",
            marginTop: "50px",
            marginLeft: "30px"
          }}
        >
          <table>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold", border: "none" }}>{localStorage.getItem("FullName")}</td>
              </tr>
              <tr>
                <td style={{ border: "none" }}>{localStorage.getItem("Address")}</td>
              </tr>
              <tr>
                <td style={{ border: "none" }}>
                  {localStorage.getItem("Mobile")} || {localStorage.getItem("Email")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            position: "fixed",
            top: "50",
            left: "0",
            marginTop: "80px",
            marginLeft: "500px",
            fontWeight: "bold",
            fontSize: "35px"
          }}
        >
          <p>Invoice</p>
        </div>

        <div
          style={{
            position: "fixed",
            top: "50",
            left: "0",
            width: "600px",
            marginTop: "130px",
            marginLeft: "30px",
            border: "solid",
            borderWidth: "1px"
          }}
        >
          <table style={{ margin: "6px" }}>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold", fontSize: "15px", border: "none" }}>TO : {this.props.customer}</td>
              </tr>
              <tr>
                <td style={{ border: "none" }}>{this.props.customerAddress}</td>
              </tr>
              <tr>
                <td style={{ border: "none" }}>
                  Post Code:{this.props.custPostCode} Company Number:{this.props.custCompanyNumber}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            position: "fixed",
            top: "50",
            left: "0",
            width: "500px",
            marginTop: "130px",
            marginLeft: "700px"
          }}
        >
          <table>
            <tbody style={{ fontWeight: "bold", fontSize: "15px", border: "none", margin: "5px" }}>
              <tr>
                <td style={{ fontWeight: "bold", border: "none" }}>Inovice No:</td>
                <td style={{ fontWeight: "bold", fontSize: "15px", border: "none" }}>INV-{this.props.invoiceNumber}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", border: "none" }}>Date:</td>
                <td style={{ border: "none" }}>{this.props.orderDate}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", border: "none" }}>Previous Balance:</td>
                <td style={{ border: "none" }}>{this.props.customerBalance}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", border: "none" }}>Current Balance:</td>
                <td style={{ border: "none" }}>{Number(this.props.customerBalance) + Number(this.props.orderTotalValue)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <Container fluid>
          <Row>
            <Col md={12}>
              <div style={{ marginTop: "220px", border: "solid", borderWidth: "2px" }}>
                <table id="table-to-xls">
                  <tr style={{ fontSize: "25px", fontWeight: "bold" }}>
                    <th style={{ minWidth: "600px" }}>Description</th>
                    <th style={{ minWidth: "150px" }}>Qty</th>
                    <th style={{ minWidth: "150px" }}>Price</th>
                    <th style={{ minWidth: "150px" }}>Total</th>
                  </tr>
                  <tbody style={{ border: "solid", borderWidth: "2px" }}>
                    {this.props.orderDetails && this.props.orderDetails.length
                      ? this.props.orderDetails.map((det) => (
                          <tr  key={det.Id}>
                            <td>
                              {det.ProductCode}-{det.Product}
                            </td>
                            <td>{det.Quantity.toFixed(2)}</td>
                            <td>{det.UnitSalesPrice && det.UnitSalesPrice}</td>
                            <td>{det.UnitSalesPrice && det.Quantity * det.UnitSalesPrice && det.UnitSalesPrice}</td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style={{ fontSize: "25px", fontWeight: "bold", border: "solid", borderWidth: "2px" }}>
                    <th>Total Line Count: {this.props.orderDetails ? this.props.orderDetails.length : 0}</th>
                    <th>
                      {this.props.orderDetails.reduce((currentTotal, det) => {
                        return parseInt(det.Quantity) + currentTotal;
                      }, 0)}
                    </th>
                    <th>Total Amount:</th>
                    <th>
                      {this.props.orderDetails.reduce((currentTotal, det) => {
                        return det.Quantity * det.UnitSalesPrice + currentTotal;
                      }, 0)}
                    </th>
                  </tr>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
        <div
          style={{
            position: "fixed",
            top: "50",
            right: "0",
            width: "600px",
            marginTop: "300px",
            marginRight: "30px"
          }}
        >
          <table>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Total Amaount:</td>
                <td>
                  {this.props.orderDetails
                    .reduce((currentTotal, det) => {
                      return det.Quantity * det.UnitSalesPrice && det.UnitSalesPrice + currentTotal;
                    }, 0)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Vat Amaount:</td>
                <td>
                  {(
                    this.props.orderDetails.reduce((currentTotal, det) => {
                      return det.Quantity * det.UnitSalesPrice && det.UnitSalesPrice + currentTotal;
                    }, 0) * 0.2
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </td>
              </tr>
              <tr style={{ fontSize: "25px", fontWeight: "bold" }}>
                <td>Total Gross Amount:</td>
                <td>
                  {(
                    this.props.orderDetails.reduce((currentTotal, det) => {
                      return det.Quantity * det.UnitSalesPrice && det.UnitSalesPrice + currentTotal;
                    }, 0) * 1.2
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
